import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, } from "react-router"
import { Loader } from "../../../components/loader"
import NoDataIcon from "../../../styles/svg/noDataIcon"


export const ReservationDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [id, setId] = useState(undefined)
    const [isLoading, setLoading] = useState(false)
    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    /*     const [deleteModal, setDeleteModal] = useState(false)
     */

    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {

                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })

        return newObj
    }

    useEffect(() => {

        setTheadValues(thead)

    }, [thead])

    useEffect(() => {

        setTdValues(td?.map((item: any) => transformObj(theadValues, item)))
    }, [td])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {

            if (item?.[0] != 'id') {
                if (index == 0) {
                    return <td key={index} ><strong>{item?.[1] == "" ? '#' : item?.[1]}</strong></td>
                }
                if (item?.[0] == 'isWaiting') {
                    return <td key={index}><strong>{item?.[1] == true ? t("wait") : t("main")}</strong></td>
                }
                if (typeof item?.[1] == 'boolean' && item?.[0] != 'isWaiting') {
                    return <td key={index}>{item?.[1] == true ? <img src="/right.png" width={20} height={20} /> : <img src="/not.png" width={20} height={20} />}</td>
                    {/* <td><AppSwitch onChange={() => { }} checked={item?.[1] ?? false} /></td> */ }
                }


                return <td key={index}>{item?.[1]}</td>
            }
        })
    }

    return <><div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id') {
                            return (

                                <th key={index}><strong>{t(item)}</strong></th>

                            )
                        }
                    })}

                    <th key={20}><strong>{t("actions")}</strong></th>

                </tr>
            </thead>
            <tbody>

                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>{fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a href={`/admin/reservations/${tdValue?.id}`} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-eye"></i></a>
                            </div>
                        </td>
                    </tr>


                })}

            </tbody>
        </table>


        {isLoading && <Loader />}

    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}