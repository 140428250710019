import { t } from "i18next";
import { useEffect, useState } from "react";
import { AppButton } from './../../components/button';
import { AppModal } from './../../components/modal';
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik";
import { useFormik } from "formik";
import { AppPagination } from "../../components/listing/pagination";
import AppointmentsDataTable from "./components/AppointmentsDataTable";
import { AddBulkAppointment, AppointmentsList, addOrEditAppointments } from "../../apis/Appointments";
import { getLang } from "../../helpers/localizationHelper";
import { AppInput } from "../../components/formsComponents/input";
import { AppSwitch } from "../../components/formsComponents/switch";
import { AppDateTimepicker } from "../../components/formsComponents/datePicker";
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist";
import { resolveRequest } from "../../helpers";
import { GetAllBranchWorkingSystems } from "../../apis/workingSystem";
import DateRangePicker from './../../components/formsComponents/dateRangePicker';
import { AppTimepicker } from './../../components/formsComponents/timePicker';
import moment from "moment";

const AppointmentsScreen = () => {
    const [requestDone, setRequestDone] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [AddBulkModal, setAddBulkModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [AppointmentsData, setAppointmentsData] = useState<any>([])
    const [currentTab, setCurrentTab] = useState(1)
    const [AllBranchWorkingSystems, setAllBranchWorkingSystems] = useState<any>()
    const [RangeDate, setRangeDate] = useState<any>({ startDate: new Date(), endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) })
    const tabs = [{ id: 1, name: t('default') }, { id: 2, name: t('waiting') }]

    const submit = async (values: any) => {
        let res = await addOrEditAppointments({
            ...values,
            appointmentTime: moment(values?.appointmentTime).format("YYYY-MM-DDTHH:mm:ss"),
            appointmentDate: moment(values?.appointmentDate).format("YYYY-MM-DDTHH:mm:ss"),
            id: null
        })
        if (res?.status) {
            resetForm({ values: {} })
            setAddModal(false)
            setRequestDone((prev: any) => !prev)
            setCurrentTab(1)
        }
    }

    const { values, handleChange, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik(formikSetup(initialValues, undefined, validationSchema, 'addAppointment', submit))
    const isValid = formikFieldValidation(touched, errors);
    const submitBulk = async (values: any) => {
        let res = await AddBulkAppointment({
            ...values,
            startDate: moment(values?.startDate).format("YYYY-MM-DDTHH:mm:ss"),
            endDate: moment(values?.endDate).format("YYYY-MM-DDTHH:mm:ss"),
        })
        if (res.status) {
            resetFormBulk({ values: {} })
            setAddBulkModal(false)
            setRequestDone((prev: any) => !prev)
        }

    }
    const { values: valuesBulk, handleChange: handleChangeBulk, handleSubmit: handleSubmitBulk, touched: touchedBulk, errors: errorBulk, setFieldValue: setFieldValueBulk, resetForm: resetFormBulk } = useFormik(formikSetup(initialValues, undefined, validationSchema, 'addBulk', submitBulk))
    const isValidBulk = formikFieldValidation(touchedBulk, errorBulk);

    useEffect(() => {
        (async () => {
            let data = await AppointmentsList({
                pageNumber: currentPage, pageSize: 10,
                ...RangeDate
            })
            setAppointmentsData(data)
        })()
    }, [currentPage, getLang(), requestDone])

    useEffect(() => {
        resolveRequest(GetAllBranchWorkingSystems, setAllBranchWorkingSystems)
    }, [getLang()])


    return (<>
        <div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>
            <div className="card p-3 mt-4 pb-1 pt-1">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="commonHeader">
                        {t('Appointments')}
                    </p>
                    <div className="d-flex">
                        <AppButton title={`${t("Add_Bulk")} +`} onClick={function (): void {
                            setAddBulkModal(true)
                        }} />
                        <AppButton title={`${t("Add_Appointment")} +`} onClick={function (): void {
                            setAddModal(true)
                        }} className="mx-2" />
                    </div>
                </div>

            </div>
            <div className="card p-3" style={{ marginTop: -15 }}>
                <div className="d-flex justify-content-end w-100 mb-2">
                    <DateRangePicker
                        onChange={(e) => {
                            (async () => {
                                setRangeDate({ startDate: e[0], endDate: e[1] })
                                let data = await AppointmentsList({ pageNumber: currentPage, pageSize: 10, startDate: e[0], endDate: e[1] })
                                setAppointmentsData(data)
                            })()
                        }} />
                </div>

                <AppointmentsDataTable setRequestDone={setRequestDone}
                    thead={["id", "appointmentDate", "appointmentTime", "guestsNumberPerReservation", "allowWaitingList"]}
                    tdData={AppointmentsData.data}
                />
                {AppointmentsData?.data?.length != 0 &&
                    <AppPagination
                        currentPage={currentPage}
                        pageCount={Math.ceil(AppointmentsData?.totalRows / 10)}
                        setCurrentPage={setCurrentPage} />
                }
            </div>
        </div>
        <AppModal show={addModal} title={t("Add_Appointment")}
            closeBtnLabel={t("close")}
            saveBtnLabel={t("save")}
            className="modal2"
            onClose={() => {
                setAddModal(false)
                setCurrentTab(1)
            }}
            body={
                <>
                    <div className="row mb-3">

                        <AppDateTimepicker require label={t('appointmentDate')}
                            onChange={(e) => {
                                setFieldValue('appointmentDate', e)
                            }}
                            errorMessage={isValid('appointmentDate')}
                            value={values?.appointmentDate}
                            showTimeInput={false} />

                        <AppTimepicker require label={t('appointmentTime')}
                            onChange={(e) => {
                                setFieldValue('appointmentTime', e)
                            }} errorMessage={isValid('appointmentTime')}
                            value={values?.appointmentTime} />


                    </div>

                    <div className="row">
                        <AppInput type="number" label={t('guestsNumberPerReservation')}
                            onChange={handleChange('guestsNumberPerReservation')}
                            errorMessage={isValid('guestsNumberPerReservation')}
                            value={values?.guestsNumberPerReservation} />
                    </div>
                    <div style={{ borderWidth: 1, borderStyle: 'dashed', padding: 20, borderRadius: 10 }}>
                        <div className="d-flex justify-content-center flex-wrap" style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', padding: 0, marginBottom: 20 }}>
                            {tabs?.map((item => {
                                return <p style={{
                                    marginBlock: 0,
                                    fontSize: "17px",
                                    color: item?.id == currentTab ? "#886bff" : "black", borderBottomColor: item?.id == currentTab ? "#886bff" : "transparent", borderBottomWidth: 1, borderBottomStyle: 'solid', cursor: 'pointer', paddingInline: 20
                                }} onClick={() => {
                                    setCurrentTab(item?.id)
                                }}>{item?.name}</p>
                            }))}
                        </div>
                        {currentTab == 1 && <>

                            <div className="row">
                                <AppInput type="number" label={t('maxReservationsNumber')} onChange={handleChange('maxReservationsNumber')} errorMessage={isValid('maxReservationsNumber')} value={values?.maxReservationsNumber} />
                                <AppInput type="number" label={t('maxGuestsNumber')} onChange={handleChange('maxGuestsNumber')} errorMessage={isValid('maxGuestsNumber')} value={values?.maxGuestsNumber} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('downPaymentPerReservation')} onChange={handleChange('downPaymentPerReservation')} errorMessage={isValid('downPaymentPerReservation')} value={values?.downPaymentPerReservation} />
                                <AppInput type="number" label={t('downPaymentPerGuest')} onChange={handleChange('downPaymentPerGuest')} errorMessage={isValid('downPaymentPerGuest')} value={values?.downPaymentPerGuest} />
                            </div>
                        </>}

                        {currentTab == 2 && <>
                            <div className="row">
                                <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("allowWaitingList")} onChange={(e: any) => {
                                    setFieldValue('allowWaitingList', e)
                                }} checked={values?.allowWaitingList} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('maxWaitingReservationsNumber')} onChange={handleChange('maxWaitingReservationsNumber')} errorMessage={isValid('maxWaitingReservationsNumber')} value={values?.maxWaitingReservationsNumber} />
                                <AppInput type="number" label={t('maxWaitingGuestsNumber')} onChange={handleChange('maxWaitingGuestsNumber')} errorMessage={isValid('maxWaitingGuestsNumber')} value={values?.maxWaitingGuestsNumber} />
                            </div>
                        </>}
                    </div>
                </>

            }
            onSaveClick={handleSubmit}
        />

        <AppModal show={AddBulkModal} title={t("Add_Bulk")}
            closeBtnLabel={t("close")}
            saveBtnLabel={t("save")}
            className="modal2"
            onClose={() => {
                setAddBulkModal(false)
            }}
            body={
                <>
                    <div className="row mb-3">
                        <AppDateTimepicker label={t('startDate')}
                            onChange={(e: any) => {
                                setFieldValueBulk('startDate', e)
                            }} require={true}
                            errorMessage={isValidBulk('startDate')}
                            value={valuesBulk?.startDate} />

                        <AppDateTimepicker label={t('endDate')} onChange={(e: any) => {
                            setFieldValueBulk('endDate', e)
                        }} errorMessage={isValidBulk('endDate')}
                            value={valuesBulk?.endDate} minDate={valuesBulk?.startDate}
                            require={true} />
                    </div>
                    <div className="row">
                        <AppDropdownlist
                            errorMessage={isValidBulk('workingSystemId')} require
                            label={t('workingSystem')}
                            onChange={(val) => {
                                setFieldValueBulk('workingSystemId', val?.target?.value)
                            }} items={AllBranchWorkingSystems?.data} value={valuesBulk?.workingSystemId} />

                    </div>
                </>

            }
            onSaveClick={handleSubmitBulk}
        />

    </>);
}

export default AppointmentsScreen;