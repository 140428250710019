import { BranchesIcon } from "../../styles/svg/logos/sidebar/BranchesIcon";
import { DatesIcon } from "../../styles/svg/logos/sidebar/DatesIcon";
import { QuestionnairesIcon } from "../../styles/svg/logos/sidebar/QuestionnairesIcon";
import { ReportsIcon } from "../../styles/svg/logos/sidebar/ReportsIcon";
import { ReservationsIcon } from "../../styles/svg/logos/sidebar/ReservationsIcon";
import { SettingsIcon } from "../../styles/svg/logos/sidebar/SettingsIcon";
import { UserManagementIcon } from "../../styles/svg/logos/sidebar/UserManagementIcon";
import { DashboardIcon } from "../../styles/svg/logos/sidebar/dashboardIcon";
import { ResturantsIcon } from "../../styles/svg/logos/sidebar/resturantsIcon";

export const DashboardHeader = [
  { groupId: 1, name: "dashboard", icon: <DashboardIcon /> },
  { groupId: 2, name: "restaurants", icon: <ResturantsIcon /> },
  { groupId: 3, name: "Branches", icon: <BranchesIcon /> },
  { groupId: 4, name: "Reservations", icon: <ReservationsIcon /> },
  { groupId: 5, name: "Dates", icon: <DatesIcon /> },
  { groupId: 6, name: "settings", icon: <SettingsIcon /> },
  { groupId: 7, name: "UserManagement", icon: <UserManagementIcon /> },
  { groupId: 8, name: "Reports", icon: <ReportsIcon /> },
  { groupId: 9, name: "surveys", icon: <QuestionnairesIcon /> },
];
