import axiosCall from "../../axios/axiosConfig";
import { endpoints } from "../../endpoints";

export const tenantManagerDashboardNumbers = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagerDashboard.numbers)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const tenantManagerDashboardReviewsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagerDashboard.reviews)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const tenantManagerDashboardReservationsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagerDashboard.reservations)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};
