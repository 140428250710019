



interface itemProp {
    title?: string
    date?: string
}
//for now for notification
export const CommonDropDown = ({ items, btnLabel, btnOnClick }: { items: itemProp[], btnLabel?: string, btnOnClick?: any }) => {
    return <div className="dropdown-menu dropdown-menu-end">
        <div id="DZ_W_Notification1" className="widget-media dz-scroll p-2" style={{ height: 380 }}>
            <ul className="timeline">
                {
                    items?.map((item: itemProp, index: any) => {
                        return <li key={index} onClick={() => {

                        }}>
                            <div className="timeline-panel">
                                <div className="media me-2">
                                    {
                                        /**here img or any thing for notification */
                                    }
                                </div>
                                <div className="media-body">
                                    <h6 className="mb-1">{item.title}</h6>
                                    <small className="d-block">{item.date}</small>
                                </div>
                            </div>
                        </li>
                    })
                }


            </ul>
        </div>
        {btnLabel && <a className="all-notification" onClick={btnOnClick}>{btnLabel}<i className="ti-arrow-end"></i></a>
        }    </div>
}