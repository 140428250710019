import { Helmet } from "react-helmet"
import { SketchPicker } from 'react-color'
import { useEffect, useState } from "react"
import { AppButton } from "../button"
import { useTranslation } from "react-i18next"
import { AppCheckBox } from "./checkbox"
import { ColorPickerModal } from "../colorPickerModal"

export const AppColorPicker = ({ label, value, onChange }: { label: string, value: string, onChange: (val: any) => void }) => {
    const { t } = useTranslation()
    // const [value, setValue] = useState()
    const [openPicker, setOpenPicker] = useState(false)
    const [colorSet, setColorSet] = useState((value == "" || value == null) ? false : true)
    const [color, setColor] = useState(value)
    useEffect(() => {
        if (colorSet == false) {
            onChange?.("")
            setOpenPicker(false)
        }
    }, [colorSet])
    useEffect(() => {
        setColorSet((value == "" || value == null) ? false : true)
    }, [value])
    return <>

        <div className="col-xl-6 col-lg-6 mb-3">



            <div className="mt-3" style={{ position: 'relative' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        {label &&
                            <h4 className="card-title">{label}</h4>
                        }
                        <div style={{}}>
                            <AppCheckBox checked={colorSet} onChange={(e) => {
                                setColorSet(e.target.checked);
                            }} label={t("setColor")} />
                        </div>
                    </div>
                    <div title={t('chooseColor')} onClick={() => {
                        if (colorSet)
                            setOpenPicker(prev => !prev)
                    }} style={{ marginTop: 10, borderRadius: 10, width: 50, height: 40, backgroundColor: value == '' ? 'white' : value, borderWidth: 2, borderColor: '#646c9a', borderStyle: 'solid', cursor: colorSet ? 'pointer' : 'not-allowed' }}></div>
                </div>

            </div>

            <ColorPickerModal
                body={<SketchPicker color={color ?? 'white'} onChange={(val: any) => {
                    setColor(val?.hex)
                    //onChange?.(val?.hex)
                }}

                />}
                show={openPicker}
                onClick={() => {
                    onChange?.(color)
                    setOpenPicker(false)

                }}
                onClose={() => {
                    setOpenPicker(false)
                }}
            />

        </div></>
}