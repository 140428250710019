import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { resolveRequest } from "../../../helpers"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../../helpers/formik"
import { useFormik } from "formik"
import { AppModal } from "../../../components/modal"
import { AppInput } from "../../../components/formsComponents/input"
import { Loader } from "../../../components/loader"
import { AppSwitch } from "../../../components/formsComponents/switch"
import { SurveyQuesDetails, addSurveyQues, deleteSurveyQues } from "../../../apis/surveyQues"
import NoDataIcon from "../../../styles/svg/noDataIcon"


export const SurveysQuestionsDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [isLoading, setLoading] = useState(false)
    const [surveyDetails, setSurveyQuesDetails] = useState()
    const [idS, setIdS] = useState(undefined)
    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {

                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })

        return newObj
    }

    useEffect(() => {
        setTheadValues(thead)
    }, [thead])

    useEffect(() => {

        setTdValues(td?.map((item: any) => transformObj(theadValues, item)))
    }, [td])

    useEffect(() => {
        if (idS)
            resolveRequest(SurveyQuesDetails, setSurveyQuesDetails, { id: idS })

    }, [editModal,])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {
            if (item?.[0] != 'id') {
                if (typeof item?.[1] == 'boolean') {
                    return <td key={index}>{item?.[1] == true ? <img src="/right.png" width={20} height={20} /> : <img src="/not.png" width={20} height={20} />}</td>
                }
                else
                    return <td key={index}><strong>{item?.[1] == "" ? '#' : item?.[1]}</strong></td>

            }


        })

    }
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addSurveyQues({ ...values, id: idS, surveyId: id })
            if (res?.status) {
                setEditModal(false)
                setRequestDone((prev: any) => !prev)

            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, surveyDetails, validationSchema, 'addSurveyQues', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <><div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id') {
                            return (
                                <th key={index}><strong>{t(item)}</strong></th>
                            )
                        }
                    })}
                    <th key={20}><strong>{t("actions")}</strong></th>

                </tr>
            </thead>
            <tbody>

                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>{fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a onClick={() => {
                                    setEditModal(true)
                                    setIdS(tdValue?.id)
                                }} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></a>
                                <a onClick={() => {
                                    setDeleteModal(true)
                                    setIdS(tdValue?.id)
                                }} className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>


                })}

            </tbody>
        </table>
        <AppModal
            show={deleteModal}

            onClose={() => {
                setDeleteModal(false)
                setIdS(undefined)
            }}
            body={<div className="text-center">
                {t('deleteSurveyquesQuote')}
            </div>}

            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={t('delete_survey_ques')}
            onSaveClick={async () => {
                try {
                    setLoading(true)
                    const res = await deleteSurveyQues({ id: idS })
                    if (res?.status) {
                        setDeleteModal(false)
                        setRequestDone((prev: any) => !prev)

                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)

                }

            }} />

        <AppModal
            className="modal2"

            show={editModal}
            onClose={() => {
                setEditModal(false)
                setIdS(undefined)
                setSurveyQuesDetails(undefined)

            }}
            body={
                <>
                    {
                        <>
                            <div className="row">
                                <AppInput className="col-lg-12 col-xl-12" label={t('text')} require onChange={handleChange('text')} errorMessage={isValid('text')} value={values?.text} />

                            </div>
                            <div className="row">
                                <div className="row">
                                    <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("enableNotes")} onChange={(e: any) => {
                                        setFieldValue('enableNotes', e)
                                    }} checked={values?.enableNotes} />
                                    <AppSwitch className="col-xl-6 col-lg-6 mb-4" label={t("isActive")} onChange={(e: any) => {
                                        setFieldValue('isActive', e)
                                    }}
                                        checked={values?.isActive} />

                                </div>
                            </div>
                        </>
                    }
                </>
            } title={t('edit_survey_ques')} onSaveClick={handleSubmit} />


        {isLoading && <Loader />}

    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}