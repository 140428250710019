import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router"
import { AppSwitch } from "../../../components/formsComponents/switch"
import { AppModal } from "../../../components/modal"
import { Loader } from "../../../components/loader"
import { addWorkingDays, deleteWorkingDays, workingDaysDetails } from "../../../apis/workingSystemDay"
import { AppInput } from "../../../components/formsComponents/input"
import { AppDropdownlist } from "../../../components/formsComponents/dropdownlist"
import moment from "moment"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../../helpers/formik"
import { useFormik } from "formik"
import { getLang } from "../../../helpers/localizationHelper"
import { GetAllWorkingDays, GetAllWorkingDaysAdd, GetAllWorkingDaysEdit } from "../../../apis/lookups"
import { resolveRequest } from "../../../helpers"
import { AppTimepicker } from "../../../components/formsComponents/timePicker"
import NoDataIcon from "../../../styles/svg/noDataIcon"

export const DaysDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { id } = useParams()
    const [ids, setId] = useState(undefined)
    const [isLoading, setLoading] = useState(false)
    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [allWorkingSystemWeekEdit, setAllWorkingWeekEdit] = useState()
    const [details, setDetails] = useState()
    const [requestDone2, setRequestDone2] = useState(false)
    const tabs = [
        { id: 1, name: t('default') },
        { id: 2, name: t('waiting') },

    ]
    const [currentTab, setCurrentTab] = useState(1)
    useEffect(() => {
        if (ids)
            resolveRequest(GetAllWorkingDays, setAllWorkingWeekEdit, { id: ids })
    }, [ids, getLang(), requestDone2])
    useEffect(() => {
        if (ids) {
            resolveRequest(workingDaysDetails, setDetails, { id: ids })

        }
    }, [ids, requestDone2])
    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {
                if (item == 'weekDay') {
                    newObj[item] = data[item]?.title
                    newObj["order"] = data[item]?.order

                } else {
                    newObj[item] = data[item]
                }
            } else {
                newObj[item] = undefined
            }
        })

        return newObj
    }

    useEffect(() => {

        setTheadValues(thead)

    }, [thead])
    function compareNumbers(a: any, b: any) {
        return a?.order - b?.order;
    }
    useEffect(() => {


        setTdValues(td?.map((item: any) => transformObj(theadValues, item)).sort(compareNumbers))

    }, [td])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {

            if (item?.[0] != 'id' && item?.[0] != 'order') {
                if (typeof item?.[1] == 'boolean') {
                    return <td key={index}>{item?.[1] == true ? <img src="/right.png" width={20} height={20} /> : <img src="/not.png" width={20} height={20} />}</td>
                    {/* <td><AppSwitch onChange={() => { }} checked={item?.[1] ?? false} /></td> */ }
                }
                else
                    return <td key={index} ><strong>{item?.[1]}</strong></td>
            }
        })
    }

    const submit2 = async (values: any,) => {

        setLoading(true)
        try {
            const res = await addWorkingDays({
                ...values, id: ids, workingSystemId: id, 
                startTime: moment(values?.startTime).format("YYYY-MM-DDTHH:mm:ss"), 
                endTime: moment(values?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
                // waitingOpenTime: values?.waitingOpenTime ? moment(values?.waitingOpenTime).format("YYYY-MM-DDTHH:mm:ssZ") : null, waitingCloseTime: values?.waitingCloseTime ? moment(values?.waitingCloseTime).format("YYYY-MM-DDTHH:mm:ssZ") : null,
            })
            if (res?.status) {
                setEditModal(false)
                setRequestDone((prev: any) => !prev)
                setRequestDone2((prev: any) => !prev)
                resetForm({ values: '' })

            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError2)
        }
        setLoading(false)
    };
    const formik2 = useFormik(
        formikSetup(initialValues, details, validationSchema, 'addWorkingSystemDay', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, setFieldValue: setFieldValue2, setFieldError: setFieldError2, resetForm } = formik2;
    const isValid2 = formikFieldValidation(touched2, errors2);
    return <> <div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id') {
                            return (
                                <th key={index}><strong>{t(item)}</strong></th>
                            )
                        }
                    })}
                    <th key={20}><strong>{t("actions")}</strong></th>
                </tr>
            </thead>
            <tbody>

                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>{fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a onClick={() => {
                                    setEditModal(true)
                                    setId(tdValue?.id)
                                }} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></a>
                                <a onClick={() => {
                                    setDeleteModal(true)
                                    setId(tdValue?.id)
                                }} className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>


                })}

            </tbody>
        </table>
        <AppModal
            show={deleteModal}

            onClose={() => {
                setDeleteModal(false)
                setId(undefined)
            }}
            body={<div className="text-center">
                {t('deleteDaysQuote')}            </div>}

            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={t('delete_days')}
            onSaveClick={async () => {
                try {
                    setLoading(true)

                    const res = await deleteWorkingDays({ id: ids })
                    if (res?.status) {
                        setDeleteModal(false)
                        setRequestDone((prev: any) => !prev)
                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }} />
        <AppModal
            className="modal2"

            show={editModal}
            onClose={() => {
                setEditModal(false)
                setCurrentTab(1)
            }}
            body={
                <>
                    <div className="row">
                        <AppDropdownlist errorMessage={isValid2('weekDayId')} require
                            label={t('weekDay')} onChange={(val) => {
                                setFieldValue2('weekDayId', val?.target?.value)
                            }} items={allWorkingSystemWeekEdit} value={values2?.weekDayId} />
                        <AppInput type="number" label={t('timeIncrement')} onChange={handleChange2('timeIncrement')} errorMessage={isValid2('timeIncrement')} value={values2?.timeIncrement} />
                    </div>
                    <div className="row">
                        <AppTimepicker require label={t('startTime')} onChange={(e) => {
                            setFieldValue2('startTime', e)
                        }} errorMessage={isValid2('startTime')} value={values2?.startTime} />
                        <AppTimepicker require label={t('endTime')} onChange={(e) => {
                            setFieldValue2('endTime', e)
                        }} errorMessage={isValid2('endTime')} value={values2?.endTime} />

                    </div>
                    <div className="row">
                        <AppInput type="number" label={t('guestsNumberPerReservation')} onChange={handleChange2('guestsNumberPerReservation')} errorMessage={isValid2('guestsNumberPerReservation')} value={values2?.guestsNumberPerReservation} />


                    </div>
                    <div style={{ borderWidth: 1, borderStyle: 'dashed', padding: 20, borderRadius: 10 }}>
                        <div className="d-flex justify-content-center flex-wrap" style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', padding: 0, marginBottom: 20 }}>
                            {tabs?.map((item => {
                                return <p style={{
                                    marginBlock: 0,

                                    color: item?.id == currentTab ? "#886bff" : "black", borderBottomColor: item?.id == currentTab ? "#886bff" : "transparent", borderBottomWidth: 1, borderBottomStyle: 'solid', cursor: 'pointer', paddingInline: 20
                                }} onClick={() => {
                                    setCurrentTab(item?.id)
                                }}>{item?.name}</p>
                            }))}
                        </div>
                        {currentTab == 1 && <>

                            <div className="row">
                                <AppInput type="number" label={t('maxReservationsNumber')} onChange={handleChange2('maxReservationsNumber')} errorMessage={isValid2('maxReservationsNumber')} value={values2?.maxReservationsNumber} />
                                <AppInput type="number" label={t('maxGuestsNumber')} onChange={handleChange2('maxGuestsNumber')} errorMessage={isValid2('maxGuestsNumber')} value={values2?.maxGuestsNumber} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('downPaymentPerReservation')} onChange={handleChange2('downPaymentPerReservation')} errorMessage={isValid2('downPaymentPerReservation')} value={values2?.downPaymentPerReservation} />
                                <AppInput type="number" label={t('downPaymentPerGuest')} onChange={handleChange2('downPaymentPerGuest')} errorMessage={isValid2('downPaymentPerGuest')} value={values2?.downPaymentPerGuest} />
                            </div>
                        </>}
                        {currentTab == 2 && <>
                            <div className="row">
                                <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("allowWaitingList")} onChange={(e: any) => {
                                    setFieldValue2('allowWaitingList', e)
                                }} checked={values2?.allowWaitingList} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('maxWaitingReservationsNumber')} onChange={handleChange2('maxWaitingReservationsNumber')} errorMessage={isValid2('maxWaitingReservationsNumber')} value={values2?.maxWaitingReservationsNumber} />
                                <AppInput type="number" label={t('maxWaitingGuestsNumber')} onChange={handleChange2('maxWaitingGuestsNumber')} errorMessage={isValid2('maxWaitingGuestsNumber')} value={values2?.maxWaitingGuestsNumber} />
                            </div>
                        </>}
                    </div>
                </>
            } title={t('edit_days')} onSaveClick={handleSubmit2} />
        {isLoading && <Loader />}
    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}