import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const ListingContactRequests = ({pageNumber = 1,pageSize = 10}) => {
    return new Promise((resolve,reject)=>{
      axiosCall.post(endpoints.contactRequests.listing,{
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "string",
        orderBy: "string",
        orderDirection: "string"
      }).then((res)=>{
        if(res.data.code == 200){
            resolve({
                data:res.data.data || res.data.obj,
                totalRows:res.data.totalRowsCount
            })
        }else reject(res.data.message)
      }).catch((e)=>{
        reject(e)
      })
    });
}


export const GetContactRequestById = (id) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.contactRequests.details,{
            id:id
        }).then((res)=>{
            if(res.data.code == 200){
                resolve({
                    data:res.data.data || res.data.obj,
                    status:true
                })
            }else reject(res.data.message)
        }).catch((e)=>{
            reject(e)
        })
    })
}

export const EditContactRequest = (data) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.contactRequests.edit,data).then((res)=>{
            if(res.data.code == 200){
                resolve({
                    data:res.data.data || res.data.obj,
                    status:true
                })
            }else reject(res.data.message)
        }).catch((e)=>{
            reject(e)
        })
    });
}


export const DeleteContactRequest = (id) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.contactRequests.delete,{
            id:id
        }).then((res)=>{
            if(res.data.code == 200){
                resolve({
                    data:res.data.data || res.data.obj,
                    status:true
                })
            }else reject(res.data.message)
        }).catch((e)=>{
            reject(e)
        })
    })
}
 
 
 
 
