import { t } from "i18next";
import { AppInput } from "../../../components/formsComponents/input";
import { Loader } from "../../../components/loader";
import { AppModal } from "../../../components/modal";
import NoDataIcon from "../../../styles/svg/noDataIcon";
import { useEffect, useState } from "react";
import { DeleteNewsLetters, EditNewsLetters, GetNewsLettersById } from "../../../apis/Newsletters";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../../helpers/formik";
import { resolveRequest } from "../../../helpers";

const NewsLettersDataTable = ({ thead, tdData, setRequestDone }: { thead: any, tdData: any, setRequestDone: (bool: any) => void }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [EditModal, setEditModal] = useState(false)
    const [NewsLettersDetails, setNewsLettersDetails] = useState<any>()
    const [id, setId] = useState(undefined)
    const [EditId, setEditId] = useState(undefined)
    const [Loading, setLoading] = useState(false)
    const [tdValues, setTdValues] = useState<any>()

    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {
                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })
        return newObj
    }

    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {
            if (item?.[0] != 'id') {
                if (index == 0) {
                    return <td key={index}>
                        {item?.[1] == "" ? '#' : item?.[1]}
                        </td>
                }
                return <td key={index}>{item?.[1]}</td>
            }
        })
    }

    useEffect(() => {
        if (EditId)
            resolveRequest(GetNewsLettersById, setNewsLettersDetails, EditId)
    }, [EditId])

    useEffect(() => {
        setTdValues(tdData?.map((item: any) => transformObj(thead, item)))
    }, [tdData])

    const submit = async (values: any) => {
        setLoading(true)
        try {
            let res = await EditNewsLetters({...values,id:EditId ?? 0})
            if (res.status == 200) {
                setEditModal(false)
                setRequestDone((prev: any) => !prev)
            }
        } catch (error) {}
        setLoading(false)
    }

    const { values, errors, handleChange, handleSubmit, touched,resetForm } = 
    useFormik(formikSetup(initialValues, NewsLettersDetails, validationSchema,
        'assignNewsLetters', submit))
    const isValid = formikFieldValidation(touched, errors);

    return (
        <>
            <div className="table-responsive">
                <table className="table table-responsive-md">
                    <thead>
                        <tr>
                            <th>#</th>
                            {
                                thead.map((item: string, index: number) => {
                                    if (item != 'id' && item != 'profileImage' && item != 'gender') {
                                        return (
                                            <th key={index}><strong>{t(item)}</strong></th>
                                        )
                                    }
                                })
                            }
                            <th><strong>{t("actions")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        {
                            tdValues?.map((res: any, index: number) => {
                                return <tr key={index}>
                                    <td><strong>{index + 1}</strong></td>
                                    {fun_tdValues(res)}
                                    {/* <td>{res.email}</td> */}
                                    <td className="d-flex">
                                        <a className="btn btn-primary shadow btn-xs sharp me-1"
                                            onClick={() => {
                                                setEditModal(true)
                                                setEditId(res?.id)
                                                setNewsLettersDetails(undefined)
                                                resetForm({ values: {} })
                                            }}>
                                            <i className="fa fa-pencil"></i>
                                        </a>

                                        <a className="btn btn-danger shadow btn-xs sharp"
                                            onClick={() => {
                                                setDeleteModal(true)
                                                setId(res?.id)
                                            }}>
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>

                <AppModal
                    show={deleteModal}
                    title={t("delete_NewsLetter")}
                    body={
                        <div className="text-center">
                            {t('Do_you_want_to_delete_this_NewsLetter')}
                        </div>
                    }
                    onSaveClick={
                        async () => {
                            setLoading(true)
                            const res = await DeleteNewsLetters(id)
                            if (res?.status) {
                                setDeleteModal(false)
                                setRequestDone((prev: any) => !prev)
                            }
                            setLoading(false)
                        }
                    }
                    onClose={
                        () => {
                            setDeleteModal(false)
                            setId(undefined)
                        }
                    }
                />

                <AppModal
                    show={EditModal}
                    title={t('edit_NewsLetter')}
                    body={
                        <div className="row">
                            <div className="col-md-12">
                            <AppInput placeholder={t('email')} label={t('email')} 
                                onChange={handleChange('email')}
                                errorMessage={isValid('email')} 
                                value={values?.email} 
                                require
                                className="col-xl-12"/>
                                </div>
                        </div>
                    }
                    onSaveClick={handleSubmit}
                    onClose={() => {
                        setEditModal(false)
                        setEditId(undefined)
                    }}
                />
                 {Loading && <Loader />}
            </div>
            {tdValues?.length > 0 ? <></> : <>
                <NoDataIcon />
            </>}
        </>
    );
}

export default NewsLettersDataTable;