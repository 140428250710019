import { useState } from "react"
import { CrossedEyeIcon } from "../../styles/svg/crossedEyeIcon"
import { EyeIcon } from "../../styles/svg/eyeIcon"

export const AppInput = ({ disable = false, className, label, placeholder, value, onChange, require = false, errorMessage, type = "text", isPassword = false }: { isPassword?: boolean, disable?: boolean, className?: string, type?: string, errorMessage: string, require?: boolean, label?: string, placeholder?: string, value: string, onChange: (val: any) => void }) => {
    const [showPassword, setShowPassword] = useState(isPassword)    
    return <div style={{ position: 'relative' }} className={`col-xl-6 col-lg-6  mb-4 ${className}`}>
        {label &&
            <h4 className="card-title">
                {label}
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </h4>
        }
        <div className={`d-flex justify-content-between w-100 align-items-center ${errorMessage ?'':'border'} mt-3 rounded form-control`}
         //@ts-ignore
         style={{ border: errorMessage ? '1px solid red' : '' }}>
            <input  readOnly={disable} value={value} onChange={(event) => {
                onChange?.(event?.target?.value)
             }} type={!!showPassword ? 'password' : "text"}
                className="input-default w-100 border-0" />
            {isPassword && <div onClick={() => { setShowPassword(prev => !prev) }}>
                {!showPassword ? <CrossedEyeIcon color="gray" /> : <EyeIcon />}
            </div>
            }
        </div>
    </div>
}