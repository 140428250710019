import { Navigate, useLocation } from "react-router-dom";
import { Layout } from "../components/layout";
import Cookies from "universal-cookie";

const PrivateRoute = ({ children }) => {
  let isAuth = "";
  let isRefreshAuth="";
  const location = useLocation();
  const cookies = new Cookies();
  isAuth = cookies.get("token");
  isRefreshAuth = cookies.get("refreshToken");
  const { pathname } = useLocation();
  const returnUrl = pathname
  if (isAuth == undefined && isRefreshAuth == undefined && (!window.location.pathname.includes("login") ||
    !window.location.pathname.includes("register"))) {
    const urlWithReturnUrl = `/login?returnURL=${returnUrl}`
    return <Navigate to={urlWithReturnUrl} state={{ from: location, search: pathname }} />;
  }
  else if (isAuth == undefined && isRefreshAuth != undefined && (!window.location.pathname.includes("login") ||
  !window.location.pathname.includes("register"))) {
    return <Layout>{children}</Layout>;
}
  return <Layout>{children}</Layout>;
}
export default PrivateRoute;


