import ReactSwitch from "react-switch"
import { getLang } from "../../helpers/localizationHelper"
export const AppSwitch = ({ className, label, checked, onChange, onClick }: { className?: string, label?: string, checked: boolean, onChange?: any, onClick?: any }) => {
    return <div className={className ? className : "col-12"}>
        <div className="form-check d-flex align-items-center">
            {label && <h4 style={{ marginInlineEnd: 10, marginInlineStart: getLang() == "en-US" ? -20 : undefined, marginTop: getLang() == "en-US" ? 5 : 0 }} className="card-title">{label}</h4>
            }
            <ReactSwitch
                checkedIcon={false}
                uncheckedIcon={false}
                onClick={onClick}
                className="appswitch"
                onColor="#6a49f2"
                style={{
                    borderRadius: 10,
                }}
                size={20}
                height={20}
                width={40}
                onChange={onChange}
                checked={checked} />

        </div>
    </div>
}
