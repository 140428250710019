// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from '@nivo/bar'
import { getLang } from '../../helpers/localizationHelper'
import { useEffect, useState } from 'react'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const BarChart = ({ data, keys, indexedBy, axisBottomLabel, axisLeftLabel }: { data: any, keys: any, indexedBy: any, axisBottomLabel: any, axisLeftLabel: any }) => {

    const [tempData, setTempData] = useState(data)
    useEffect(() => {
        setTempData(data)
    }, [data])
    return <div
        className="pie"
        style={{
            height: "500px",
            width: '100%'

        }}
    > <ResponsiveBar

            data={tempData}
            keys={keys}
            indexBy={indexedBy}
            margin={{ top: 50, right: getLang() == "ar-SA" ? 0 : 0, bottom: 50, left: getLang() == "ar-SA" ? 50 : 50 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={["#4527a0"]}

            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}

            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisBottomLabel,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: getLang() == "ar-SA" ? 20 : 5,
                tickRotation: 0,
                legend: axisLeftLabel,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            label={''}
            /*  legends={[
            {

                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: getLang() == "ar-SA" ? 30 : 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                symbolSpacing: getLang() == "ar-SA" ? -30 : 10,
                itemDirection: getLang() == "ar-SA" ? "right-to-left" : 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]} */
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
        />
    </div>
}