import { t } from 'i18next';
import { DeleteAppointment, GetAppointmentsDetails, addOrEditAppointments } from '../../../apis/Appointments';
import { AppModal } from '../../../components/modal';
import { useEffect, useState } from 'react';
import { AppInput } from '../../../components/formsComponents/input';
import { AppSwitch } from '../../../components/formsComponents/switch';
import { resolveRequest } from '../../../helpers';
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from '../../../helpers/formik';
import { useFormik } from 'formik';
import NoDataIcon from '../../../styles/svg/noDataIcon';
import { AppDateTimepicker } from '../../../components/formsComponents/datePicker';
import { AppTimepicker } from "../../../components/formsComponents/timePicker";
import moment from 'moment';

const AppointmentsDataTable = ({ thead, tdData, setRequestDone }: { thead: any[], tdData: [], setRequestDone: (bool: any) => void }) => {
  const [DeleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(undefined)
  const [tdValues, setTdValues] = useState<any>()
  const [editModal, seteditModal] = useState(false)
  const [theadValues, setTheadValues] = useState(thead)
  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const [AppointmentsDetails, setAppointmentsDetails] = useState<any>()
  const [requestDone2, setRequestDone2] = useState(false)

  const transformObj = (keys: Array<string>, data: any) => {
    const newObj: any = {}
    keys?.map((item) => {
      if (data[item] != undefined) {
        newObj[item] = data[item]
      } else {
        newObj[item] = undefined
      }
    })
    return newObj
  }

  useEffect(() => {
    setTheadValues(thead)
  }, [thead])

  useEffect(() => {
    setTdValues(tdData?.map((item: any) => transformObj(theadValues, item)))
  }, [tdData])

  const fun_tdValues = (itemValue: any) => {
    return Object.entries(itemValue)?.map((item: any, index: number) => {
      if (item?.[0] != 'id') {
        if (index == 0) {
          return <td key={index}>
            {item?.[1] == "" ? '#' : item?.[1]}
          </td>
        }
        if (item?.[0] == "downPaymentPerGuest") {
          return;
        }
      
        if (typeof item?.[1] == 'boolean') {
          return <td key={index}>{item?.[1] == true ? <img src="/right.png" width={20} height={20} className="mx-3" /> : <img src="/not.png" width={20} height={20} className="mx-3" />}</td>
        }
        return <td key={index}>{item?.[1]}</td>
      }
    })
  }
  const tabs = [{ id: 1, name: t('default') }, { id: 2, name: t('waiting') }]
  useEffect(() => {
    if (id) {
      resolveRequest(GetAppointmentsDetails, setAppointmentsDetails, id)
    }
  }, [id , requestDone2])  
  const submit = async (values: any) => {
    setIsLoading(true)
    try {
      const res = await addOrEditAppointments({ id: id, ...values,
        appointmentTime: moment(values?.appointmentTime).format("YYYY-MM-DDTHH:mm:ss"),
        appointmentDate: moment(values?.appointmentDate).format("YYYY-MM-DDTHH:mm:ss"),

      })
      
      if (res.status) {
        seteditModal(false)
        setRequestDone((prev: any) => !prev)
        setRequestDone2((prev: any) => !prev)
        // resetForm({ values: '' })
      }
    } catch (e: any) {
      // setBackEndErrors(e?.response?.data?.errors, setFieldError)
    }
    setIsLoading(false)
  }
  const { values, handleSubmit, handleChange, touched, resetForm, errors, setFieldValue, setFieldError } = useFormik(formikSetup(initialValues, AppointmentsDetails?.data, validationSchema, 'editAppointment', submit))
  const isValid = formikFieldValidation(touched, errors)
     
  return (
    <>
      <div className="table-responsive">
        <table className='table table-responsive-md'>
          <thead>
            <tr>
              <th>#</th>
              {theadValues?.map((item: string, index: any) => {
                if (item != 'id') {
                  return (
                    <th key={index}><strong>{t(item)}</strong></th>
                  )
                }
              })}
              <th><strong>{t("actions")}</strong></th>
            </tr>
          </thead>
          <tbody>
            {tdValues?.map((res: any, index: number) => {
              return <tr key={index}>
                <td><strong>{index + 1}</strong></td>
                {fun_tdValues(res)}
                <td>
                  <div className="d-flex">
                    <a className="btn btn-primary shadow btn-xs sharp me-1"
                      onClick={() => {
                        seteditModal(true)
                        setId(res?.id)
                        setCurrentTab(1)
                      }}>
                      <i className="fa fa-pencil"></i>
                    </a>
                    <a className="btn btn-danger shadow btn-xs sharp"
                      onClick={() => {
                        setDeleteModal(true)
                        setId(res?.id)
                      }}>
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </td>
              </tr>
            })
            }
          </tbody>
        </table>
        {tdValues?.length > 0 ? <></> : <>
          <NoDataIcon />
        </>}
      </div >
      <AppModal show={editModal} title={t("edit_Appointment")}
        closeBtnLabel={t("close")}
        saveBtnLabel={t("save")}
        className="modal2"
        onClose={() => {
          seteditModal(false)
          setCurrentTab(1)
        }}
        body={
          <>
            <div className="row mb-3">
              <AppDateTimepicker require label={t('appointmentDate')}
                onChange={(e) => {
                  setFieldValue('appointmentDate', e)
                }}
                errorMessage={isValid('appointmentDate')}
                value={values?.appointmentDate}
                showTimeInput={false} />

              <AppTimepicker 
                require 
                label={t('appointmentTime')}
                value={values?.appointmentTime} 
                onChange={(e) => {
                  setFieldValue('appointmentTime', e)
                }} 
                errorMessage={isValid('appointmentTime')}
                />
            </div>
            <div className="row">
              <AppInput type="number" label={t('guestsNumberPerReservation')}
                onChange={handleChange('guestsNumberPerReservation')}
                errorMessage={isValid('guestsNumberPerReservation')}
                value={values?.guestsNumberPerReservation} />
            </div>


            <div style={{ borderWidth: 1, borderStyle: 'dashed', padding: 20, borderRadius: 10 }}>
              <div className="d-flex justify-content-center flex-wrap" style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', padding: 0, marginBottom: 20 }}>
                {tabs?.map((item => {
                  return <p style={{
                    marginBlock: 0,
                    fontSize: "17px",
                    color: item?.id == currentTab ? "#886bff" : "black", borderBottomColor: item?.id == currentTab ? "#886bff" : "transparent", borderBottomWidth: 1, borderBottomStyle: 'solid', cursor: 'pointer', paddingInline: 20
                  }} onClick={() => {
                    setCurrentTab(item?.id)
                  }}>{item?.name}</p>
                }))}
              </div>
              {currentTab == 1 && <>

                <div className="row">
                  <AppInput type="number" label={t('maxReservationsNumber')} onChange={handleChange('maxReservationsNumber')} errorMessage={isValid('maxReservationsNumber')} value={values?.maxReservationsNumber} />
                  <AppInput type="number" label={t('maxGuestsNumber')} onChange={handleChange('maxGuestsNumber')} errorMessage={isValid('maxGuestsNumber')} value={values?.maxGuestsNumber} />
                </div>
                <div className="row">
                  <AppInput type="number" label={t('downPaymentPerReservation')} onChange={handleChange('downPaymentPerReservation')} errorMessage={isValid('downPaymentPerReservation')} value={values?.downPaymentPerReservation} />
                  <AppInput type="number" label={t('downPaymentPerGuest')} onChange={handleChange('downPaymentPerGuest')} errorMessage={isValid('downPaymentPerGuest')} value={values?.downPaymentPerGuest} />
                </div>
              </>}

              {currentTab == 2 && <>
                <div className="row">
                  <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("allowWaitingList")} onChange={(e: any) => {
                    setFieldValue('allowWaitingList', e)
                  }} checked={values?.allowWaitingList} />
                </div>
                <div className="row">
                  <AppInput type="number" label={t('maxWaitingReservationsNumber')} onChange={handleChange('maxWaitingReservationsNumber')} errorMessage={isValid('maxWaitingReservationsNumber')} value={values?.maxWaitingReservationsNumber} />
                  <AppInput type="number" label={t('maxWaitingGuestsNumber')} onChange={handleChange('maxWaitingGuestsNumber')} errorMessage={isValid('maxWaitingGuestsNumber')} value={values?.maxWaitingGuestsNumber} />
                </div>
              </>}
            </div>
          </>

        }
        onSaveClick={handleSubmit}
      />
      <AppModal
        show={DeleteModal}
        title={t('delete_Appointment')}
        body={
          <div className="text-center">
            {t('Do_you_want_to_delete_this_Appointment')}
          </div>
        }
        onSaveClick={async () => {
          setLoading(true)
          let data = await DeleteAppointment(id)
          if (data.status) {
            setDeleteModal(false)
            setRequestDone((prev: any) => !prev)
          }
          setLoading(false)
        }
        }

        onClose={() => {
          setDeleteModal(false)
          setId(undefined)
        }} />
    </>
  );
}

export default AppointmentsDataTable;