import DatePicker from "react-datepicker";

export const AppDateTimepicker = ({ label, time = false, value, onChange, placeholder, errorMessage, require = false, className,minDate,showTimeInput=false}: { require?: boolean, errorMessage?: string, time?: boolean, label?: string, placeholder?: string, value: any, onChange: (val: any) => void ,className?:string,minDate?:any,showTimeInput?:boolean}) => {
    return <div className={`${className ?className : 'col-xl-6'}  col-lg-6  col-12 mb-3`}>
        {label &&
            <h4 className="card-title">
                {label}
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </h4>
        }
        <DatePicker
            className={`form-control ${errorMessage ?'border-red':''}`}
            wrapperClassName=" mt-3 col-12"
            selected={value}
            dateFormat={`${showTimeInput ? 'yyyy-MM-dd  hh:mm a':'yyyy-MM-dd'}`}
            onChange={onChange}
            minDate={minDate}
            showTimeInput={showTimeInput}
        />
    </div>
}