import { useNavigate, useParams } from "react-router";
import { useEffect } from 'react';
import { resolveRequest } from "../../helpers";
import { EditContactRequest, GetContactRequestById } from "../../apis/contactRequests";
import { useState } from 'react';
import { AppInput } from "../../components/formsComponents/input";
import { t } from "i18next";
import { AppButton } from "../../components/button";
import { Loader } from "../../components/loader";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../helpers/formik";
import { AppMobileNum } from "../../components/formsComponents/mobileNum";
import { AppTextArea } from "../../components/formsComponents/textArea";

const EditContactRequestsScreen = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [ContactRequestDetails, setContactRequestDetails] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [country, setCountry] = useState<any>()
    const [requestDone, setRequestDone] = useState(false)

    useEffect(() => {
        if (id) {
            resolveRequest(GetContactRequestById, setContactRequestDetails, id)
        }
    }, [id])
    const submit = async (values: any) => {
        setLoading(true)
        try {
            const res = await EditContactRequest({ ...values, id: id ?? 0, dialCode: country })
            if (res.status) {
                setRequestDone(prev => !prev)
            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    }

    const { values, handleChange, handleSubmit, errors, touched, setFieldError } = useFormik(
        formikSetup(initialValues, ContactRequestDetails?.data, validationSchema, 'assignContactRequest', submit)
    )
    const isValid = formikFieldValidation(touched, errors)
    useEffect(() => {
        
        if (ContactRequestDetails)
            setCountry(ContactRequestDetails?.data?.dialCode)
    }, [ContactRequestDetails])

    return (
        <>
            <div className="pe-5 ps-5">
                <div className="card p-3 mt-4 pb-1 pt-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="commonHeader">
                            {t('edit_contactRequests')}
                        </p>
                        <AppButton title=">" onClick={() => {
                            navigate('/admin/contactRequests')
                        }} />

                    </div>
                </div>

                <div style={{ marginTop: -15 }} className="card p-3 pb-5 pt-2">
                    <div className="row">
                        <AppInput placeholder={t('name')} label={t('name')} require
                            onChange={handleChange('name')} errorMessage={isValid('name')}
                            value={values?.name ?? ''} />

                        <AppInput placeholder={t('email')} label={t('email')} require
                            onChange={handleChange('email')} errorMessage={isValid('email')}
                            value={values?.email ?? ''} />
                    </div>
                    <div className="row">
                        <AppInput placeholder={t('company')} require
                            label={t('company')}
                            onChange={handleChange('company')}
                            errorMessage={isValid('company')}
                            value={values?.company ?? ''} />


                        <AppMobileNum country={country}
                            setCountry={setCountry}
                            placeholder={t('mobile')}
                            label={t('mobile')}
                            require
                            onChange={handleChange('mobile')}
                            errorMessage={isValid('mobile')} value={values?.mobile} />

                    </div>

                    <div className="row">
                        <AppInput placeholder={t('website')} label={t('website')}
                            onChange={handleChange('website')} errorMessage={isValid('website')}
                            value={values?.website ?? ''} className="col-xl-12" require />

                        <AppTextArea require placeholder={t('message')} label={t('message')}
                            onChange={handleChange('message')} errorMessage={isValid('message')}
                            value={values?.message ?? ''} />
                    </div>

                    <div className="row">
                        <AppTextArea placeholder={t('response')} label={t('response')}
                            onChange={handleChange('response')} errorMessage={isValid('response')}
                            value={values?.response ?? ''}
                        />

                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <AppButton title={t("save")} onClick={handleSubmit} />
                    </div>
                </div>

                {isLoading && <Loader />}

            </div>
        </>
    );
}

export default EditContactRequestsScreen;