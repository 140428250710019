import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { resolveRequest } from "../../helpers"
import { GetAllSurveyStyles } from "../../apis/lookups"
import { getLang } from "../../helpers/localizationHelper"
import { addSurvey, listingSurveys } from "../../apis/surveys"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { AppButton } from "../../components/button"
import { SurveyDataTable } from "./components/surveyDataTable"
import { AppPagination } from "../../components/listing/pagination"
import { AppModal } from "../../components/modal"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { Loader } from "../../components/loader"

export const SurveyScreen = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [addModal, setAddModal] = useState(false)
    const [requestDone, setRequestDone] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [allSurveyStyles, setAllSurveyStyles] = useState()
    const [allSurveys, setAllSurveys] = useState<any>()
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        resolveRequest(GetAllSurveyStyles, setAllSurveyStyles)



    }, [getLang()])

    useEffect(() => {
        (async () => {

            const res = await listingSurveys({ pageNumber: currentPage, pageSize: 10 })
            setAllSurveys(res)
        })()

    }, [requestDone, currentPage, getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addSurvey({ ...values, id: null, description: "", enableNotes: false, isActive: false })
            if (res?.status) {
                navigate(`/admin/surveys/${res?.data}`)
                setAddModal(false)
                resetForm({ values: {} })



            }
        } catch (e) {

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addSurvey', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <><div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>

        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('surveys')}
                </p>
                <AppButton outlined title={t("add_survey")} onClick={() => {
                    setAddModal(true)
                }} />

            </div>
        </div>
        <div className="card p-3" style={{ marginTop: -15 }}>

            <SurveyDataTable setRequestDone={setRequestDone} thead={["id", "title", "isActive", "enableNotes"]} td={allSurveys?.data} />
            {allSurveys?.data?.length != 0 && <AppPagination currentPage={currentPage} pageCount={Math.ceil(allSurveys?.totalRows / 10)} setCurrentPage={setCurrentPage} />
            }
        </div>
        <AppModal
            className="modal2"

            show={addModal}
            onClose={() => {
                setAddModal(false)
                resetForm({ values: {} })

            }}
            body={
                <div className="row">
                    <AppInput label={t('survey_name')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                    <AppDropdownlist errorMessage={isValid('surveyRateStyleId')} require label={t('survey_style')} onChange={(val) => {
                        setFieldValue('surveyRateStyleId', val?.target?.value)
                    }} items={allSurveyStyles} value={values?.surveyRateStyleId} />

                </div>
            } title={t('add_survey_modal')} onSaveClick={handleSubmit} />

    </div>
        {isLoading && <Loader />}
    </>

}