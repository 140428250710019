import type { Dayjs } from 'dayjs';
import  { useState } from 'react';
import { DatePicker } from 'antd';
import localeAr from 'antd/es/date-picker/locale/ar_EG';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { getLang } from './../../helpers/localizationHelper';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const DateRangePicker = ({onChange,values}:{ onChange: (date:any) => void,values?:any}) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(values);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;
    return !!tooEarly || !!tooLate;
  };   
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const dateFormat = 'DD/MM/YYYY';
  const date=new Date()
  return (
    <RangePicker
      defaultValue={[dayjs(`${date.getDate() < 10 ? '0':''}${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`, dateFormat), dayjs(`${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getDate() < 10 ? '0':''}${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getDate()}/${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getMonth()+1}/${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getFullYear()}`, dateFormat)]}
      value={dates || value}
      locale={getLang() == 'ar-SA'?localeAr:localeEn}
      disabledDate={disabledDate}
      onCalendarChange={(val) => {
        setDates(val);
      }}
      onChange={(val,dateString) => {
        onChange(dateString)
        setValue(val);
      }}
      allowClear={false}
      // onOpenChange={onOpenChange}
      changeOnBlur
    />
  );
};

export default DateRangePicker;

// const  DateRangePicker= () => {
//   return ( <></> );
// }
 
// export default DateRangePicker;