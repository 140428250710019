import AppRoutes from "./AppRoutes";
import { CustomRouter } from "./customRouter";
import history from "./historyObj";
import { QueryClientProvider,QueryClient } from 'react-query';
const queryClient = new QueryClient()
const AppRouting = () => {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
       <CustomRouter history={history}>
      <AppRoutes />
    </CustomRouter>
  </QueryClientProvider>
 
  );
};

export default AppRouting;
