import * as React from "react";
const LogoIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 153.5 107.8"
    style={{
      enableBackground: "new 0 0 153.5 107.8",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{"\n\t.st0{fill:#6A49F2;}\n"}</style>
    <g>
      <g>
        <path
          className="st0"
          d="M1.6,7.1C1.8,4.8,3.1,3.9,5,4.6c0.7,0.3,1.1,0.8,1.2,1.5c0,0.3,0.2,0.7,0.2,1c0,0.7,0,1.4,0,2.1 c0,0.3,0,0.5,0,0.8c0,4.7,0,9.4,0,14.1c0,2.4,0,2.4,2.4,2.4c2.4,0,2.4,0,2.4-2.4c0-5.5,0-11-0.1-16.5c-0.1-1.3,0.3-2.4,1.5-3.1 c0.4,0,0.8,0,1.1,0c1.4,0.1,2.2,0.8,2.2,2.3c0,6.2,0,12.4,0,18.6c0,0.9,0.3,1.2,1.1,1.2c0.9,0,1.8-0.1,2.7,0 c1,0.1,1.2-0.4,1.2-1.2c0-2.7-0.1-5.5-0.1-8.2c0-3.6,0-7.2,0.1-10.8c0-1.2,0.9-1.8,2.1-1.9c1.6,0,2.4,0.5,2.4,1.9 c0.1,2.6,0,5.2,0,7.9c0,6.8-0.1,13.7,0,20.5c0.1,5.6-3.4,10.3-8.5,12c-0.8,0.3-1.1,0.6-1.1,1.5c0,10.1-0.1,20.2,0.1,30.2 c0.1,8.2-0.1,16.4,0,24.6c0,0.7,0.1,1.4-0.4,2.1c-0.8,1-1.8,0.9-2.8,0.8c-0.9-0.1-1.3-0.8-1.5-1.7c-0.3-1.7-0.1-3.5-0.1-5.2 c0-16.6,0-33.1,0-49.7c0-2.2,0-2.2-2.1-3.1c-3.7-1.5-6-4.3-7-8.1c-0.4-1.4-0.4-2.8-0.4-4.2c0-8.7,0-17.5,0-26.2 C1.6,7.5,1.6,7.3,1.6,7.1z M13.6,31.2C13.6,31.2,13.6,31.2,13.6,31.2c-2.1,0.1-4.2,0-6.3,0.1c-0.4,0-0.9-0.1-0.9,0.5 c0.1,2-0.3,4,0.5,5.9c1.1,2.8,4.2,4.8,7.3,4.6c3.1-0.2,5.7-2.4,6.4-5.5c0.4-1.5,0.2-3,0.3-4.5c0-0.8-0.4-1.1-1.2-1.1 C17.6,31.2,15.6,31.2,13.6,31.2z"
        />
        <path
          className="st0"
          d="M147.5,91.4c0-9.2,0-18.4,0-27.6c0-0.5,0-1-0.4-1.3c-0.2-0.2-0.6-0.2-0.9-0.2c-1.6,0-3.2,0-4.9,0 c-1.1,0-2.5-0.1-3.1-1.1c-0.3-0.5-0.3-1.2-0.3-1.8c0-13.3,0-26.7,0-40c0-2.5,0-5,0.8-7.3c1-3.1,3.5-5.5,6.4-6.7 c2.8-1.2,7.1-1.9,7.1,2.2c0,31.4,0,62.9,0,94.3c0,1,0,2-0.4,2.8s-1.3,1.6-2.2,1.5c-1.7-0.2-2.1-2.4-2.1-4.1 C147.5,98.5,147.5,95,147.5,91.4z M142.7,34c0,7.4,0,14.9,0.1,22.3c0,0.5,0.1,1,0.4,1.2c0.2,0.1,0.4,0.1,0.7,0.1 c0.8,0,1.6,0,2.4,0c0.4,0,0.8,0,1-0.3c0.1-0.2,0.1-0.5,0.1-0.7c0-9.3,0-18.6,0-27.9c0-4.7,0-9.4,0-14.1c0-1.2,0-2.4,0-3.5 c0-1.1,0.2-1.7-1-1.5c-1.5,0.3-2.7,2.6-3.2,3.9c-0.6,1.9-0.6,4-0.6,6C142.7,24.4,142.7,29.2,142.7,34z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M99.3,6.8c6.2,2.6,11.8,6.5,16.5,11.5c9.7,10.3,15.4,24.6,14.5,38.8c0,0.3-0.1,0.7-0.1,1.1 c-1.9,21.3-18.5,40.5-39.4,45.3S46.7,98.3,35.7,80s-8.8-43.6,5.2-59.8C50.1,9.6,64,3.5,78,3C85.4,2.7,92.7,4,99.3,6.8z  M125.6,54.9c0-0.2,0-0.4,0-0.6c0.2-3.2-0.3-6.4-0.9-9.6c-1-5.5-3.1-10.5-6.1-15.3c-3-4.8-6.6-9-11.2-12.3 c-0.2-0.3-0.5-0.5-0.8-0.6c-2.2-1.8-4.7-3.2-7.3-4.4c-2-1.1-4.1-1.9-6.2-2.5c-0.6-0.1-1.1-0.3-1.7-0.5C88.1,8,84.6,7.6,81.1,7.5 C77,7.3,73,7.6,69,8.7c-3.1,0.8-6.2,1.8-9.1,3.1c-6.6,3-12,7.4-16.6,13c-4.6,5.8-7.7,12.3-9.3,19.5c-1,4.3-1.2,8.7-0.8,13.1 c0.2,2.5,0.4,5.1,1.2,7.5c2,6.1,4.5,11.9,8.5,17.1c7.6,9.8,21.3,17.9,35.7,17.9c5,0,9.8-0.7,14.5-2.2c6.4-2,12.2-5.1,17.1-9.6 c5.5-4.9,9.6-10.9,12.3-17.8c1.6-4.2,2.5-8.5,2.9-13C125.7,56.6,125.7,55.8,125.6,54.9z"
        />
        <g>
          <path
            className="st0"
            d="M67.5,41.7c-5.8,0-11.5,0-17.2,0c0-5.8,0-11.4,0-17.2c5.7,0,11.4,0,17.2,0C67.5,30.2,67.5,35.9,67.5,41.7z  M63.6,37.7c0-2.9,0-5.6,0-8.2c0-0.9-0.5-1-1.2-1c-2.3,0-4.6,0-6.9,0c-0.8,0-1.2,0.2-1.2,1.1c0,2.4,0,4.8,0,7.2 c0,0.3,0.5,1,0.7,1C57.8,37.7,60.6,37.7,63.6,37.7z"
          />
          <path
            className="st0"
            d="M108.8,41.7c-5.8,0-11.5,0-17.3,0c0-5.8,0-11.5,0-17.2c5.7,0,11.5,0,17.3,0 C108.8,30.2,108.8,35.9,108.8,41.7z M95.5,33.1c0,1.2-0.1,2.3,0.1,3.5c0.1,0.4,0.7,1.1,1.1,1.1c2.3,0.1,4.7,0.1,7,0 c0.4,0,1.1-0.7,1.1-1.1c0.1-2.3,0.1-4.7,0-7c0-0.4-0.7-1-1.1-1.1c-2.3-0.1-4.7-0.1-7,0c-0.4,0-1,0.7-1.1,1.1 C95.4,30.7,95.5,31.9,95.5,33.1z"
          />
          <path
            className="st0"
            d="M50.6,66.1c5.6,0,11.1,0,16.7,0c0,5.7,0,11.2,0,16.9c-5.5,0-11,0-16.7,0C50.6,77.5,50.6,71.9,50.6,66.1z  M54.6,70.2c0,3.1,0,5.9,0,8.8c2.9,0,5.8,0,8.6,0c0-3,0-5.9,0-8.8C60.3,70.2,57.5,70.2,54.6,70.2z"
          />
          <path
            className="st0"
            d="M72.5,47.2c0-4.2,0-8.1,0-12.2c1.4,0,2.6,0,4,0c0,5.3,0,10.6,0,16.1c-0.6,0-1.2,0.1-1.8,0.1 c-6.1,0-12.3,0.1-18.4,0c-1.7,0-2.2,0.5-2,2.1c0.1,1.7,0,3.4,0,5.2c-1.4,0-2.6,0-4,0c0-3.7,0-7.4,0-11.2 C57.7,47.2,65,47.2,72.5,47.2z"
          />
          <path
            className="st0"
            d="M86.4,83.1c-3.8,0-7.6,0-11.5,0c0-1.2,0-2.5,0-4c2.4,0,4.9,0,7.5,0c0-3.1,0-6,0-9.2c-3.2,0-6.4,0-9.7,0 c0-3.8,0-7.3,0-11c1.3,0,2.5,0,3.9,0c0,2.3,0,4.4,0,6.7c3.3,0,6.5,0,9.8,0C86.4,71.6,86.4,77.2,86.4,83.1z"
          />
          <path
            className="st0"
            d="M91.6,74.7c1.3,0,2.5,0,3.9,0c0,1.1,0,2.1,0,3.1c-0.1,0.9,0.3,1.3,1.3,1.3c2.2-0.1,4.5-0.1,6.7,0 c1,0,1.4-0.3,1.3-1.3c0-1,0-2,0-3.1c1.4,0,2.7,0,4,0c0,2.8,0,5.5,0,8.3c-5.7,0-11.4,0-17.2,0C91.6,80.3,91.6,77.5,91.6,74.7z"
          />
          <path
            className="st0"
            d="M100.4,69.9c0-1.3,0-2.5,0-3.9c1.4,0,2.8,0,4.4,0c0-5.6,0-11.1,0-16.6c1.4,0,2.6,0,4,0c0,6.8,0,13.5,0,20.5 C106.1,69.9,103.3,69.9,100.4,69.9z"
          />
          <path
            className="st0"
            d="M82.9,61.2c0-3.9,0-7.8,0-11.8c1.3,0,2.6,0,4,0c0,2.2,0.1,4.3,0,6.4c0,1.1,0.4,1.5,1.5,1.5 c3.2,0,6.4,0,9.6,0c0,1.4,0,2.6,0,3.9C93,61.2,88,61.2,82.9,61.2z"
          />
          <path
            className="st0"
            d="M86.4,39.5c-1.3,0-2.5,0-3.9,0c0-3.6,0-7.2,0-11c-2.6,0-5.1,0-7.7,0c0-1.5,0-2.7,0-4.1c3.8,0,7.7,0,11.6,0 C86.4,29.5,86.4,34.4,86.4,39.5z"
          />
          <path
            className="st0"
            d="M98.3,47.3c0,1.3,0,2.5,0,3.8c-1.5,0-3,0-4.5,0c0-1.3,0-2.5,0-3.8C95.2,47.3,96.7,47.3,98.3,47.3z"
          />
          <path
            className="st0"
            d="M61.5,56.8c1.5,0,2.9,0,4.4,0c0,1.3,0,2.5,0,3.9c-1.4,0-2.8,0-4.4,0C61.5,59.5,61.5,58.2,61.5,56.8z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default LogoIcon;
