import LogoIcon from "../styles/svg/logos/logoIcon"

export const Loader = () => {
    return <div id="loader">
        <div className="loader-wrap">
            <div className="loader-box">
                <div className="icon">
                    <LogoIcon />
                </div>
            </div>
        </div>
    </div>
}