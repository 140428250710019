import { useTranslation } from "react-i18next"
//@ts-ignore
import TimeInput from 'material-ui-time-picker'

export const AppTimepicker = ({ label, value, onChange, placeholder,className, errorMessage, require = false, }: { require?: boolean, errorMessage?: string, label?: string, placeholder?: string,className?:string, value: any, onChange: (val: any) => void }) => {
    const { t } = useTranslation()
    return <div className={`col-xl-6 col-lg-6 mb-3 ${className}`}>
        {label &&
            <h4 className="card-title">
                {label}
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </h4>
        }
        <TimeInput
            className={`form-control mt-3 ${errorMessage ?'border-red':''}`}
            locale="en"
            mode='12h'
            okLabel={t('save')}
            cancelLabel={t('close')}
            value={value}
            onChange={onChange}
        />
    </div>
}