import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const UserMenu = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.UserProfile.UserMenu, "")
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res.data.obj,
          });
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const UserAllData = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.UserProfile.userAdminData, "")
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res.data.obj,
          });
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
