import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingBranchManagers = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const branchManagerDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addBranchManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.add, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editBranchManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.edit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const assignBranchManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.assign, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const branchesLookup = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagers.branches)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj || []);
        } else reject(res?.data?.data || res?.data?.obj || []);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
