import moment from "moment";

export const toBase64 = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});


export const resolveRequest = async (request:any, store:any, ...params:any) => {
    let data = await request(...params);
    store(data);
};
  
export const isoConverter = (iso:string) => {
    return moment(iso,"YYYY-MM-DDTHH:mm:ssZ").toDate()
}