import { useTranslation } from "react-i18next"
import { AppInput } from "../components/formsComponents/input"
import { LanguageDropDown } from "../components/layout/components/header/languageDropDown"
import { getLang } from "../helpers/localizationHelper"
import { AppCheckBox } from "../components/formsComponents/checkbox"
import { useEffect, useState } from "react"
import { loginAPI } from "../apis/auth"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../helpers/formik"
import { useNavigate } from "react-router"
import Cookies from "universal-cookie";
import moment from "moment"
import { useSearchParams } from "react-router-dom"
export const LoginScreen = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)
    const cookies = new Cookies();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const returnURL = searchParams.get("returnURL")
    const submit = async (values: any) => {
        setLoading(true)
        try {
            const res = await (loginAPI({ ...values }))                        
            if (res?.status) {
                cookies.set("token", `Bearer ${res?.token}`, { expires: moment(res?.tokenTime).toDate(), path: "/" })
                cookies.set("refreshToken", `${res?.refreshToken}`,{expires:moment(res?.refreshTokenTime).toDate(),path:"/"})                 
                if (returnURL != null) {
                    navigate(`${returnURL}`)
                } else {
                    navigate(`/admin`)
                }
                window.location.reload()
            }
        } catch (e: any) {
            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };
    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'login', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);
    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);
    return <div className="full_bg">
        <div className="container">
            <section className="signup_section">
                <div className="d-flex w-100 justify-content-center align-items-baseline">
                    <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className="top_part w-100">
                        <a className="navbar-brand" href="#">
                            <img src="/images/logo/logofull-white.png" alt="image" />
                        </a>
                    </div>
                    <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                        <LanguageDropDown />
                    </div>
                </div>
                <div className="signup_form">
                    <div className="section_title">
                        <h2> <span>{t('Login to')}</span> {t("your account")}</h2>
                        <p>{t("Fill all fields so we can get some info about you.")} <br /> {t("We'll never send you spam")}</p>

                    </div>
                    <form className="mt-4">
                        <div className="form-group">
                            <AppInput className="col-xl-12 col-lg-12" value={values?.email} onChange={handleChange('email')} label={t("email")} errorMessage={isValid('email')} require/>
                        </div>
                        <div className="form-group" style={{ position: 'relative' }}>
                            <AppInput isPassword={true} type="password" className="col-xl-12 col-lg-12" value={values?.password} onChange={handleChange('password')} label={t("password")} errorMessage={isValid('password')} require/>
                        </div>
                        <div className="form-group">
                            {/* <AppCheckBox checked={rememberMe} onChange={(e) => {
                                setRememberMe(e.target.checked);
                                localStorage.setItem('remember', e.target.checked)
                            }} label={t("Remember me")} /> */}
                        </div>
                        <div className="form-group">
                            <button className="btn puprple_btn" onClick={(e) => {
                                e.preventDefault()
                                handleSubmit?.()
                            }}
                            style={{border:'1px solid #6A49F2'}}>{t("signIn")}</button>
                        </div>
                    </form>
                
                </div>
            </section>
        </div>
    </div>
}
