import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingSurveyQuestions = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveyQues.listing, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const SurveyQuesDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveyQues.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addSurveyQues = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveyQues.add, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteSurveyQues = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveyQues.delete, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
          });
        } else
          reject({
            status: false,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
