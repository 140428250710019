import { useState } from "react"
import { NotificationIcon } from "../../styles/svg/logos/header/notificationIcon"
import { ScheduleIcon } from "../../styles/svg/logos/header/scheduleIcon"
import { SearchIcon } from "../../styles/svg/logos/header/searchIcon"
import { HeaderSearchInput } from "../formsComponents/headerSearchInput"
import { CommonDropDown } from "./components/header/commonDropDown"
import { NavLink } from "./components/header/navLink"
import { TimeLineDropDown } from "./components/header/timelineDropDown"
import { ProfileDropdown } from "./components/header/profileDropdown"
import { LanguageDropDown } from "./components/header/languageDropDown"
import { useTranslation } from "react-i18next"

export const Header = () => {
    const { t } = useTranslation()
    const [searchVal, setSearchVal] = useState('')
    return <div className="header">
        <div className="header-content">
            <nav className="navbar navbar-expand">
                <div className="collapse navbar-collapse justify-content-between">
                    <div className="header-left">
                        <div className="dashboard_bar">
                            {t('adminPanel')}
                        </div>
                    </div>
                    <div className="header-right d-flex align-items-center">
                        {/*    <div className="input-group search-area">
                            <HeaderSearchInput placeholder={t("Search here...")} value={searchVal} onChange={(event) => {
                                setSearchVal(event?.target?.value)
                            }} />
                            <span className="input-group-text"><a href="javascript:void(0)">
                                <SearchIcon />
                            </a></span>
                        </div> */}
                        <ul className="navbar-nav">
                            <LanguageDropDown />
                            <li className="nav-item dropdown notification_dropdown">
                                <NavLink icon={<NotificationIcon />} num={18} />

                                <CommonDropDown items={[{ title: 'زياد', date: '22/7/2023' }]} />
                            </li>

                            {/*    <li className="nav-item dropdown notification_dropdown">
                                <NavLink icon={<ScheduleIcon />} />
                                <TimeLineDropDown items={
                                    [{ title: "Youtube, a video-sharing website, goes live", date: "10 minutes ago" },
                                    { title: "Youtube, a video-sharing website, goes live", date: "10 minutes ago" }]} />

                            </li> */}
                            <ProfileDropdown />

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
}