import Axios from "axios";
import { baseURL } from "../endpoints";
import { getLang } from "../../helpers/localizationHelper";
const axiosCall = Axios.create({
  baseURL: baseURL,
  /*   headers: {
    "Accept-Language": getLang() == "ar" ? "ar-SA" : "en-US",
  }, */
});

export default axiosCall;
