import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { getLang } from "../../helpers/localizationHelper"
import { resolveRequest } from "../../helpers"
import { GetAllSurveyStyles } from "../../apis/lookups"
import { SurveyDetails, addSurvey } from "../../apis/surveys"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../helpers/formik"
import { useFormik } from "formik"
import { AppButton } from "../../components/button"
import { AppFileInput } from "../../components/formsComponents/fileInput"
import { AppTextArea } from "../../components/formsComponents/textArea"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { Loader } from "../../components/loader"
import { AppModal } from "../../components/modal"
import { AppSwitch } from "../../components/formsComponents/switch"
import { SurveyDataTable } from "./components/surveyDataTable"
import { addSurveyQues, listingSurveyQuestions } from "../../apis/surveyQues"
import { SurveysQuestionsDataTable } from "./components/surveysQuestionsDataTable"

export const EditSurveyScreen = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [requestDone, setRequestDone] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [allSurveyStyles, setAllSurveyStyles] = useState()
    const [allSurvQues, setAllSurvQues] = useState<any>()
    const [surveyDetails, setSurveyDetails] = useState()
    useEffect(() => {
        resolveRequest(SurveyDetails, setSurveyDetails, { id: id })
        resolveRequest(listingSurveyQuestions, setAllSurvQues, { id: id })
    }, [id, getLang(), requestDone])
    useEffect(() => {
        resolveRequest(GetAllSurveyStyles, setAllSurveyStyles)

    }, [getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addSurvey({ ...values, id: id, })


        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)

        }
        setLoading(false)
    };
    const submit2 = async (values: any) => {

        setLoading(true)
        try {
            const res = await addSurveyQues({ ...values, id: null, surveyId: id })
            if (res?.status) {
                resetForm({ values: {} })

                setAddModal(false)
                setRequestDone((prev: any) => !prev)

            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)


        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, surveyDetails, validationSchema, 'editSurvey', submit),
    );
    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addSurveyQues', submit2),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, setFieldValue: setFieldValue2, setFieldError: setFieldError2, resetForm } = formik2;

    const isValid = formikFieldValidation(touched, errors);
    const isValid2 = formikFieldValidation(touched2, errors2);
    return <div className="pe-5 ps-5">


        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('edit_survey')}
                </p>
                <AppButton title=">" onClick={() => {
                    navigate('/admin/surveys')
                }} />

            </div>
        </div>

        <div style={{ marginTop: -15 }} className="card p-3 pb-5 pt-2">


            <div className="row">
                <AppInput label={t('survey_name')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                <AppDropdownlist errorMessage={isValid('surveyRateStyleId')} require label={t('survey_style')} onChange={(val) => {
                    setFieldValue('surveyRateStyleId', val?.target?.value)
                }} items={allSurveyStyles} value={values?.surveyRateStyleId} />
            </div>
            <div className="row">
                <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("enableNotes")} onChange={(e: any) => {
                    setFieldValue('enableNotes', e)
                }} checked={values?.enableNotes} />
                <AppSwitch className="col-xl-6 col-lg-6 mb-4" label={t("isActive")} onChange={(e: any) => {
                    setFieldValue('isActive', e)
                }}
                    checked={values?.isActive} />

            </div>
            <AppTextArea placeholder={t('description')} label={t('description')} onChange={handleChange('description')} errorMessage={isValid('description')} value={values?.description ?? ''} />

            <div className="d-flex justify-content-end mt-5">
                <AppButton title={t("save")} onClick={handleSubmit} />
            </div>
        </div>


        <div className="card p-3 mt-5 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('survey_ques')}
                </p>
                <AppButton title={t("add_survey_ques")} onClick={() => {
                    setAddModal(true)
                }} />

            </div>
        </div>
        <div style={{ marginTop: -15 }} className="card p-3  pb-1 pt-1">

            <SurveysQuestionsDataTable setRequestDone={setRequestDone} thead={["text", "id", "isActive", "enableNotes",]} td={allSurvQues} />
        </div>
        <AppModal
            className="modal2"

            show={addModal}
            onClose={() => {
                resetForm({ values: {} })

                setAddModal(false)

            }}
            body={
                <>

                    <AppInput className="col-lg-12 col-xl-12" label={t('text')} require onChange={handleChange2('text')} errorMessage={isValid2('text')} value={values2?.text} />


                    <div className="row">
                        <div className="row">
                            <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("enableNotes")} onChange={(e: any) => {
                                setFieldValue2('enableNotes', e)
                            }} checked={values2?.enableNotes} />
                            <AppSwitch className="col-xl-6 col-lg-6 mb-4" label={t("isActive")} onChange={(e: any) => {
                                setFieldValue2('isActive', e)
                            }}
                                checked={values2?.isActive} />

                        </div>
                    </div>     </>
            } title={t('add_survey_ques')} onSaveClick={handleSubmit2} />
        {isLoading && <Loader />}

    </div>
}