import { t } from "i18next";
import ContactRequestsDataTable from './components/ContactRequestsDatatable';
import { useEffect } from 'react';
import { ListingContactRequests } from "../../apis/contactRequests";
import { useState } from 'react';
import { getLang } from "../../helpers/localizationHelper";
import { AppPagination } from "../../components/listing/pagination";

const ContactRequestsScreen = () => {
      const [ContactRequestsData,setContactRequestsData]=useState<any>()
      const [currentPage, setCurrentPage] = useState(1);
      const [requestDone,setRequestDone]=useState(false)
    useEffect(()=>{
       (async()=>{
        let data=await ListingContactRequests({pageNumber:currentPage,pageSize:10})
        setContactRequestsData(data)
       })()
    },[currentPage,getLang(),requestDone])

    return ( <>
      <div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>
            <div className="card p-3 mt-4 pb-1 pt-1">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="commonHeader">
                        {t('ContactRequests')}
                    </p>
                </div>
            </div>
            <div className="card p-3" style={{ marginTop: -15 }}>
             <ContactRequestsDataTable thead={["id","name","email","company","dialCode","mobile","website","message","closed"]} 
                 tData={ContactRequestsData?.data} 
                 setRequestDone={setRequestDone} />
                    {ContactRequestsData?.data?.length != 0 && 
            <AppPagination currentPage={currentPage} 
            pageCount={Math.ceil(ContactRequestsData?.totalRows / 10)} 
            setCurrentPage={setCurrentPage} />
            }
            </div>
        </div>
    </> );
}
 
export default ContactRequestsScreen;