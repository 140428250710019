import { useTranslation } from "react-i18next"
import { AppButton } from "../../components/button"
import { AppInput } from "../../components/formsComponents/input"
import { useEffect, useState } from "react"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { updateProfile, userProfileData } from "../../apis/auth"
import { AppMobileNum } from "../../components/formsComponents/mobileNum"
import { AppRadioButtons } from "../../components/formsComponents/radioButtons"
import { AppDateTimepicker } from "../../components/formsComponents/datePicker"
import PlusIcon from "../../styles/svg/plusIcon"
import RemoveIcon from "../../styles/svg/removeIcon"
import { resolveRequest, toBase64 } from "../../helpers"
import { QueryClient } from "react-query"
import { baseURLPhotos } from "../../apis/endpoints"

export const ProfileScreen = () => {
    const { t } = useTranslation()
    const [country, setCountry] = useState<any>()
    const [userProfile, setUserProfile] = useState<any>()
    const [rerequest, setrerequest] = useState(false)
    const [logoFile, setLogoFile] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [previewLogo, setPreviewLogo] = useState<any>()
    const queryClient = new QueryClient()
    useEffect(() => {

        resolveRequest(userProfileData, setUserProfile)
    }, [rerequest])
    useEffect(() => {
        if (userProfile)
            setCountry(userProfile?.dialCode)
    }, [userProfile])
    const submit2 = async (values: any) => {
        try {
            setLoading(true)
            const res = await updateProfile({ data: { ...values, id: userProfile?.id, profileImage: logoFile ?? "", isProfileImageChanged: logoFile || values?.profileImage == null ? true : false, dialCode: country } })
            if (res?.status) {
                setTimeout(() => {
                    window.location.reload()

                }, 1000);
                setrerequest(prev => !prev)
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }

    };
    const formik2 = useFormik(
        formikSetup(initialValues, userProfile, validationSchema, 'updateProfile', submit2),
    );
    const { handleChange, handleSubmit, values, touched, errors, resetForm, setFieldValue } = formik2;
    const isValid = formikFieldValidation(touched, errors);

    return <div className="pe-5 ps-5">
        <div className="card p-3 mt-4 pb-1 pt-1 pb-3">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="commonHeader">
                    {t('profile')}
                </h1>


            </div>
        </div>
        <div className="card p-3 pb-5 pt-2 mt-0">

            <div className=" mt-3 mb-4 d-flex   w-100 justify-content-between colAtr">

                <div className=" content">
                    <div className="row">
                        <AppInput className="col-12" value={values?.firstName} 
                        onChange={handleChange('firstName')} label={t("firstName")} 
                        errorMessage={isValid('firstName')} 
                        require={true}
                        />
                        <AppInput className="col-12" value={values?.lastName} 
                        onChange={handleChange('lastName')} label={t("lastName")} 
                        errorMessage={isValid('lastName')} require={true}/>
                    </div>
                    <div className="row">
                        <AppMobileNum className="col-12" country={country} setCountry={setCountry} placeholder={t('mobile')} label={t('mobile')} require onChange={handleChange('mobile')} errorMessage={isValid('mobile')} value={values?.mobile} />

                        <AppInput className="col-12" value={values?.email} onChange={handleChange('email')} label={t("email")} errorMessage={isValid('email')} require={true}/>
                    </div>
                    <div className="row">

                        <AppRadioButtons
                            buttons={[{
                                label: t("female"), onChange: () => {
                                    setFieldValue("gender", true)
                                }, checked: values?.gender
                            }, {
                                label: t("male"),
                                onChange: () => {
                                    setFieldValue("gender", false)
                                },
                                checked: !values?.gender
                            }]}

                        />
                        <AppDateTimepicker label={t('dateOfBirth')} onChange={(e: any) => {
                            setFieldValue('dateOfBirth', e)
                        }} errorMessage={isValid('dateOfBirth')} value={values?.dateOfBirth} />
                    </div>
                    <div className="mt-3">
                        <AppButton title={t("save")} onClick={handleSubmit} />
                    </div>
                </div>
                <input id="file" accept=".jpg, .png ,.jpeg" onChange={async (event: any) => {
                    setLogoFile(await toBase64(event.target.files?.[0]))
                    const objectUrl = URL.createObjectURL(event.target.files?.[0])
                    setPreviewLogo(objectUrl)
                    setFieldValue('profileImage', null)

                }} style={{ display: 'none' }} className="form-control mt-3" type="file" />
                <div className="img">
                    <div style={{ position: 'relative', marginBottom: 20, width: 200 }}>
                        {<img style={{
                            width: 200,
                            height: 200,
                            borderRadius: "50%",
                            objectFit: "cover",

                        }} src={values?.profileImage ? (baseURLPhotos + "/" + values?.profileImage) : previewLogo ? previewLogo : values?.gender == false ? '/man.jpg' : '/woman.jpg'} />}
                        <div style={{
                            position: 'absolute', bottom: -10, right: '31%',
                            textAlign: 'center',
                            width: 80,
                            backgroundColor: 'white',
                            borderWidth: .5, borderColor: 'black', borderStyle: 'solid', borderRadius: 20,
                            display: 'flex',
                            justifyContent: 'center'


                        }}>
                            <div style={{ marginInlineEnd: 10 }} onClick={() => {
                                if (logoFile || values?.profileImage) {
                                    setPreviewLogo(null)
                                    setLogoFile(null)
                                    setFieldValue('profileImage', null)
                                    if (document.getElementById("file")) {
                                        //@ts-ignore
                                        document.getElementById("file").value = ""
                                    }
                                }


                            }}>
                                <RemoveIcon color={(logoFile || values?.profileImage) ? "url(#wRKXFJsqHCxLE9yyOYHkza)" : "lightGray"} />
                            </div>
                            <label
                                htmlFor="file"
                                className="plus"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <PlusIcon /></label>

                        </div>

                    </div>
                    <p style={{ textAlign: 'center', color: '#828690', margin: 0 }}>{userProfile?.fullName}</p>
                    <p style={{ textAlign: 'center', color: '#828690', margin: 0 }}>{userProfile?.email}</p>

                </div>
            </div>
        </div>
    </div>
}