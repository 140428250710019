

export const AppCheckBox = ({ label, checked, onChange }: { label: string, placeholder?: string, checked: boolean, onChange: (val: any) => void }) => {
    return <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center' }}>

        <label style={{
            fontWeight: 'bold',
            marginInlineStart: 6,
            color: "#6A49F2",
            marginBottom: '0px'
        }} >
            {label}
        </label>

        <input style={{
            accentColor: "#6A49F2",
            marginInlineStart: 0,
        }} checked={checked} onChange={onChange} type="checkbox" />

    </div >
}