import { useEffect, useState } from "react";
import NewsLettersDataTable from "./components/NewsLettersDataTable";
import { t } from "i18next";
import { ListingNewsLetters } from "../../apis/Newsletters";
import { getLang } from "../../helpers/localizationHelper";
import { AppPagination } from "../../components/listing/pagination";

const NewsLettersScreen = () => {
    const [NewsLettersData, setNewsLettersData] = useState<any>()
    const [requestDone, setRequestDone] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        (async () => {
            let  data  = await ListingNewsLetters({ pageNumber: currentPage, pageSize: 10 })
            setNewsLettersData(data)
        })()
    }, [currentPage, requestDone, getLang()])
         
    return (<>
        <div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>
            <div className="card p-3 mt-4 pb-1 pt-1">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="commonHeader">
                        {t('NewsLetters')}
                    </p>
                </div>
            </div>
            <div className="card p-3" style={{ marginTop: -15 }}>
                <NewsLettersDataTable setRequestDone={setRequestDone}
                    thead={["id","email"]} tdData={NewsLettersData?.data} />

            {NewsLettersData?.data?.length != 0 && 
            <AppPagination currentPage={currentPage} 
            pageCount={Math.ceil(NewsLettersData?.totalRows / 10)} 
            setCurrentPage={setCurrentPage} />
            }
            </div>
        </div>
    </>)
}

export default NewsLettersScreen;