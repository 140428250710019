import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import '../'
export const ColorPickerModal = ({ className, closeBtnLabel, body, show, onClose, onClick }: { className?: string, onClick?: any, closeBtnLabel?: string, show: boolean, body: any, onClose?: any }) => {
    const { t } = useTranslation()
    return <><Modal

        dialogClassName={'myModal'}
        show={show} onHide={onClose} >

        {body}
        <div className="modal-footer justify-content-between">
            <button onClick={onClose} type="button" className="btn btn-danger light" data-bs-dismiss="modal">{closeBtnLabel ?? t("close")}</button>
            <button onClick={onClick} type="button" className="btn btn-primary light" data-bs-dismiss="modal">{t("save")}</button>

        </div>
    </Modal>
    </>

}