import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingSurveys = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveys.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addSurvey = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveys.addEdit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteSurvey = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveys.delete, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
          });
        } else
          reject({
            status: false,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const SurveyDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.surveys.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
