import axiosCall from "../../axios/axiosConfig";
import { endpoints } from "../../endpoints";

export const branchManagerDashboardNumbers = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagerDashboard.numbers)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const branchManagerDashboardReviewsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagerDashboard.reviews)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const branchManagerDashboardReservationsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branchManagerDashboard.reservations)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};
