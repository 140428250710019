/* import LogoIcon from "../../styles/svg/logos/logoIcon"
import LogoTitleIcon from "../../styles/svg/logos/logoTitleIcon"
 */
export const NavHeader = () => {
    return <div className="nav-header">
        <a href="/admin" className="brand-logo">
            <img src="/images/logo/logo-icon.svg" className="logo-abbr" />
            <img src="/images/logo/logo-text.svg" className="logo-title" />
        </a>
        <div className="nav-control">
            <div className="hamburger">
                <span className="line"></span><span className="line"></span><span className="line"></span>
            </div>
        </div>
    </div>
}