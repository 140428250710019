import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { resolveRequest } from "../../helpers"
import { GetAllSurveyStyles } from "../../apis/lookups"
import { getLang } from "../../helpers/localizationHelper"

import { AppPagination } from "../../components/listing/pagination"

import { Loader } from "../../components/loader"
import { listingReservations } from "../../apis/reservations"
import { ReservationDataTable } from "./components/reservationDataTable"

export const ReservationsScreen = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [addModal, setAddModal] = useState(false)
    const [requestDone, setRequestDone] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [allSurveyStyles, setAllSurveyStyles] = useState()
    const [allSurveys, setAllSurveys] = useState<any>()
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        resolveRequest(GetAllSurveyStyles, setAllSurveyStyles)



    }, [getLang()])

    useEffect(() => {
        (async () => {

            const res = await listingReservations({ pageNumber: currentPage, pageSize: 10 })
            setAllSurveys(res)
        })()

    }, [requestDone, currentPage, getLang()])

    return <><div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>

        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('reservations')}
                </p>


            </div>
        </div>
        <div className="card p-3" style={{ marginTop: -15 }}>

            <ReservationDataTable setRequestDone={setRequestDone} thead={["id", "dateSlot", "timeSlot", "isWaiting", "partySize", "status"]} td={allSurveys?.data} />
            {allSurveys?.data?.length != 0 && <AppPagination currentPage={currentPage} pageCount={Math.ceil(allSurveys?.totalRows / 10)} setCurrentPage={setCurrentPage} />
            }
        </div>

    </div>
        {isLoading && <Loader />}
    </>

}