import { useEffect, useState } from "react"
import { AppButton } from "../../components/button"
import { useTranslation } from "react-i18next"
import { AppModal } from "../../components/modal"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { AppInput } from "../../components/formsComponents/input"

import { Loader } from "../../components/loader"
import { listingRestaurant } from "../../apis/restaurants"
import { useNavigate } from "react-router"
import { AppPagination } from "../../components/listing/pagination"
import { getLang } from "../../helpers/localizationHelper"
import { addBranchManager, listingBranchManagers } from "../../apis/branchManagers"
import { BranchManagersDataTable } from "./components/branchManagersDataTable"
import { AppMobileNum } from "../../components/formsComponents/mobileNum"

export const BranchManagersScreen = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [requestDone, setRequestDone] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [allAdminUsers, setAllAdminUsers] = useState<any>()
    const [currentPage, setCurrentPage] = useState(1);
    const [country, setCountry] = useState<any>()
    useEffect(() => {
        (async () => {
            const res = await listingBranchManagers({ pageNumber: currentPage, pageSize: 10 })
            setAllAdminUsers(res)
        })()

    }, [currentPage, requestDone, getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addBranchManager({ ...values, dialCode: country })
            if (res?.status) {
                //  navigate(`/admin/restaurants/${res?.data}`)
                setCountry(undefined)
                resetForm({ values: {} })
                setAddModal(false)

                setRequestDone((prev: any) => !prev)


            }
        } catch (e) {

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addBranchManager', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <><div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>

        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('branchManagers')}
                </p>
                <AppButton outlined title={t("add_branchManager")} onClick={() => {
                    setAddModal(true)
                }} />

            </div>
        </div>
        <div className="card p-3" style={{ marginTop: -15 }}>

            <BranchManagersDataTable setRequestDone={setRequestDone} thead={["profileImage", "gender", "fullName", "id", "fullMobile", "email", "branch",]} td={allAdminUsers?.data} />

        </div>
        <AppModal
            className="modal2"

            show={addModal}
            onClose={() => {
                resetForm({ values: {} })
                setCountry(undefined)

                setAddModal(false)
            }}
            body={
                <>
                    <div className="row">
                        <AppInput placeholder={t('firstName')} label={t('firstName')} require onChange={handleChange('firstName')} errorMessage={isValid('firstName')} value={values?.firstName} />
                        <AppInput placeholder={t('lastName')} label={t('lastName')} require onChange={handleChange('lastName')} errorMessage={isValid('lastName')} value={values?.lastName} />

                    </div>
                    <div className="row">
                        <AppInput placeholder={t('email')} label={t('email')} require onChange={handleChange('email')} errorMessage={isValid('email')} value={values?.email} />
                        <AppMobileNum country={country} setCountry={setCountry} placeholder={t('mobile')} label={t('mobile')} require onChange={handleChange('mobile')} errorMessage={isValid('mobile')} value={values?.mobile} />

                    </div>
                </>
            } title={t('add_branchManager_modal')} onSaveClick={handleSubmit} />


    </div>
        {isLoading && <Loader />}
    </>

}