import { t } from "i18next";
import { useEffect, useState } from "react";
import { AppSwitch } from "../../../components/formsComponents/switch";
import { Link } from "react-router-dom";
import { AppModal } from "../../../components/modal";
import { DeleteContactRequest } from "../../../apis/contactRequests";
import { Loader } from "../../../components/loader";
import NoDataIcon from "../../../styles/svg/noDataIcon";
import { getLang } from "../../../helpers/localizationHelper";

const ContactRequestsDataTable = ({ thead, tData, setRequestDone }: { thead: any, tData: any, setRequestDone: (bool: any) => void }) => {
    const [tdValues, setTdValues] = useState<any>()
    const [DeleteModal, setDeleteModal] = useState(false)
    const [Id, setId] = useState()
    const [Loading, setLoading] = useState(false)
    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {
                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })
        return newObj
    }

    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {
            if (item?.[0] != 'id') {
                if (index == 0) {
                    return <td key={index}>
                        {item?.[1] == "" ? '#' : item?.[1]}
                    </td>
                }
                if (item?.[0] == "dialCode") {
                    return;
                }
                if (item?.[0] == "mobile") {
                    return;

                }
                if (typeof item?.[1] == 'boolean') {
                    return <td key={index}>{item?.[1] == true ? <img src="/right.png" width={20} height={20} className="mx-3"/> : <img src="/not.png" width={20} height={20} className="mx-3"/>}</td>
                }
                return <td key={index}>{item?.[1]}</td>
            }
        })
    }

    useEffect(() => {
        setTdValues(tData?.map((item: any) => transformObj(thead, item)))
        // tData?.map((res: any, index: number) => setDialCode((current: any) => [...current, res.dialCode]))
    }, [tData])

    return (
        <>
            <div className="table-responsive">
                <table className="table table-responsive-md">
                    <thead>
                        <tr>
                            {
                                thead.map((res: string, index: number) => {
                                    if (res != 'id' && res != 'profileImage' && res != 'gender' && res != 'dialCode'&& res != 'mobile') {
                                        return <th key={index}><strong>{t(res)}</strong></th>
                                    }
                                })
                            }
                            <th><strong>{t("mobile")}</strong></th>
                            <th><strong>{t("actions")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tdValues?.map((res: any, index: number) => {
                                return <tr key={index}>
                                    {fun_tdValues(res)}
                                    <td style={{ direction: 'ltr', textAlign: getLang() == 'ar-SA' ? 'end' : 'start' }}>
                                        {res.dialCode}{res.mobile}
                                    </td>

                                    <td className="d-flex">
                                        <Link className="btn btn-primary shadow btn-xs sharp me-1"
                                            to={`/admin/contactRequests/${res.id}`}>
                                            <i className="fa fa-pencil"></i>
                                        </Link>
                                        <a className="btn btn-danger shadow btn-xs sharp"
                                            onClick={() => {
                                                setDeleteModal(true)
                                                setId(res.id)
                                            }}>
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>

                <AppModal
                    show={DeleteModal}
                    title={t('delete_ContactRequests')}
                    body={
                        <div className="text-center">
                            {t('Do_you_want_to_delete_this_ContactRequests')}
                        </div>
                    }
                    onSaveClick={async () => {
                        setLoading(true)
                        let data = await DeleteContactRequest(Id)
                        if (data.status) {
                            setDeleteModal(false)
                            setRequestDone((prev: any) => !prev)
                        }
                        setLoading(false)
                    }
                    }

                    onClose={() => {
                        setDeleteModal(false)
                        setId(undefined)
                    }} />
                {Loading && <Loader />}
            </div>
            {tdValues?.length > 0 ? <></> : <>
                <NoDataIcon />
            </>}
        </>
    );
}

export default ContactRequestsDataTable;