import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";


export const ListingNewsLetters = ({pageNumber = 1, pageSize = 10}) => {
    return new Promise((resolve,reject)=>{
      axiosCall.post(endpoints.Newsletter.listing,{
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "string",
        orderBy: "string",
        orderDirection: "string"
      }).then((res)=>{
        if(res?.data?.code == 200){
            resolve({
              data:res?.data?.data || res?.data?.obj,
              totalRows:res?.data?.totalRowsCount
            })
        }else reject(res?.message)
      }).catch((e)=>{
        reject(e)
      })
    });
}


export const  GetNewsLettersById = (id) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.Newsletter.details,{
            id:id
        })
        .then((res)=>{
        if(res?.data?.code == 200){
            resolve(res?.data?.data || res?.data?.obj)
        } else reject(res?.data?.message)
        }).catch((e)=>{
            reject(e)
        })
    });
}
 
export const DeleteNewsLetters = (id) => {
  return new Promise((resolve,reject)=>{
    axiosCall.post(endpoints.Newsletter.delete,{
      id:id
    }).then((res)=>{
      if(res.data.code == 200){
        resolve({
          data:res.data.data || res.data.obj,
          status:true
        })
      }else reject(res.data.message)
    }).catch((e)=>{
      reject(e)
    })
  });
}

export const EditNewsLetters = (data) => {
  return new Promise((resolve,reject)=>{
    axiosCall.post(endpoints.Newsletter.edit,data).then((res)=>{
      if(res.data.code == 200){
        resolve({
          data:res.data.data || res.data.obj,
          status:res.status
        })
      }else reject(res.data.message)
    }).catch((e)=>{
      reject(e)
    })
  });
}
 
 
