import { useEffect, useState } from "react"
import { AppModal } from "../../../../components/modal"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Loader } from "../../../../components/loader"
import { SubscriptionDetails, addSubscription, deleteSubscription } from "../../../../apis/restaurantSupscriptions"
import { AppInput } from "../../../../components/formsComponents/input"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../../../helpers/formik"
import { resolveRequest } from "../../../../helpers"
import NoDataIcon from "../../../../styles/svg/noDataIcon"

export const SubscriptionDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [isLoading, setLoading] = useState(false)
    const [supscriptionDetails, setSubscriptionDetails] = useState()
    const [idS, setIdS] = useState(undefined)
    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {

                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })

        return newObj
    }

    useEffect(() => {
        setTheadValues(thead)
    }, [thead])

    useEffect(() => {

        setTdValues(td?.map((item: any) => transformObj(theadValues, item)))
    }, [td])

    useEffect(() => {
        if (idS)
            resolveRequest(SubscriptionDetails, setSubscriptionDetails, { id: idS })

    }, [editModal,])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {
            if (item?.[0] != 'id') {
                return <td key={index}><strong>{item?.[1] == "" ? '#' : item?.[1]}</strong></td>
            }
        })
    }
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addSubscription({ ...values, id: idS, tenantId: id })
            if (res?.status) {
                setEditModal(false)
                setRequestDone((prev: any) => !prev)

            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, supscriptionDetails, validationSchema, 'addSubscription', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <> <div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id') {
                            return (
                                <th key={index}><strong>{t(item)}</strong></th>
                            )
                        }
                    })}
                    <th key={20}><strong>{t("actions")}</strong></th>

                </tr>
            </thead>
            <tbody>

                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>{fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a onClick={() => {
                                    setEditModal(true)
                                    setIdS(tdValue?.id)
                                }} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></a>
                                <a onClick={() => {
                                    setDeleteModal(true)
                                    setIdS(tdValue?.id)
                                }} className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>


                })}

            </tbody>
        </table>
        <AppModal
            show={deleteModal}

            onClose={() => {
                setDeleteModal(false)
                setIdS(undefined)
            }}
            body={<div className="text-center">
                {t('deleteSubscription')}
            </div>}

            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={t('delete_Subscription')}
            onSaveClick={async () => {
                try {
                    setLoading(true)
                    const res = await deleteSubscription({ id: idS })
                    if (res?.status) {
                        setDeleteModal(false)
                        setRequestDone((prev: any) => !prev)

                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)

                }

            }} />

        <AppModal
            className="modal2"

            show={editModal}
            onClose={() => {
                setEditModal(false)
                setIdS(undefined)
                setSubscriptionDetails(undefined)

            }}
            body={
                <>
                    {
                        <>
                            <div className="row">
                                <AppInput type="numberOfBranches" placeholder={t('numberOfBranches')} label={t('numberOfBranches')} require onChange={handleChange('numberOfBranches')} errorMessage={isValid('numberOfBranches')} value={values?.numberOfBranches} />
                                <AppInput type="numberOfUsers" placeholder={t('numberOfUsers')} label={t('numberOfUsers')} require onChange={handleChange('numberOfUsers')} errorMessage={isValid('numberOfUsers')} value={values?.numberOfUsers} />

                            </div>
                            <div className="row">
                                <AppInput type="numberOfMonths" placeholder={t('numberOfMonths')} label={t('numberOfMonths')} require onChange={handleChange('numberOfMonths')} errorMessage={isValid('numberOfMonths')} value={values?.numberOfMonths} />
                                <AppInput type="amount" placeholder={t('amount')} label={t('amount')} require onChange={handleChange('amount')} errorMessage={isValid('amount')} value={values?.amount} />

                            </div>
                        </>
                    }
                </>
            } title={t('edit_subscription')} onSaveClick={handleSubmit} />


        {isLoading && <Loader />}

    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}