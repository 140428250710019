import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
interface SelectedItem {
    title: string,
    id: number | string
}
export const AppDropdownlist = ({ className, label, items, onChange, value, placeholder, require = false, errorMessage }: { className?: string, errorMessage: string, require?: boolean, label?: string, items: any | SelectedItem[], value: string | number, onChange: (val: any) => void, placeholder?: string }) => {
    const { t } = useTranslation()
    const [data, setData] = useState(items)
    useEffect(() => {

        setData(items)
    }, [items,])
    return <div className={`col-xl-6 col-lg-6 mb-4 ${className}`}>
        {label &&
            <h4 className="card-title">
                {label}
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </h4>
        }
        <select style={{ border: errorMessage ? '1px solid red' : '' }} defaultValue={undefined} value={value ?? null} onChange={onChange} className="form-control wide mt-3">{/* default-select  */}
            <option disabled selected value="">{t("choose")}</option>
            {data && data?.map((item: SelectedItem) => {
                return <option key={item?.id} value={item?.id}>{item.title}</option>
            })

            }
        </select>
    </div>
}