import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const AppointmentsList = ({ pageNumber = 1, pageSize = 10 ,startDate,endDate}) => {
    return new Promise((resolve, reject) => {
        axiosCall.post(endpoints.Appointments.listing, {
           pageNumber: pageNumber,
            pageSize: pageSize,
            searchText: "string",
            startDate: startDate,
            endDate: endDate,
            orderBy: "string",
            orderDirection: "string"
        }).then((res) => {
            if (res?.data?.code == 200) {
                resolve({
                    data: res.data.data || res.data.obj,
                    totalRows: res?.data?.totalRowsCount
                })
            } else reject(res?.data?.message)
        }).catch((e) => {
            reject(e)
        })
    });
}

export const addOrEditAppointments = (data) => {
    return new Promise((resolve, reject) => {
        axiosCall.post(endpoints.Appointments.addOrEdit, data).then((res) => {
            console.log("res 1= ",res);
            if (res.data.code == 200) {
                resolve({
                    status: true,
                    data: res.data.data || res.data.obj
                })
            } else {
                console.log("res2 = ",res);

                reject(res.data.message)
            }

        }).catch((e) => {
            reject(e)
        })
    });
}

export const GetAppointmentsDetails = (id) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.Appointments.details,{
            id:id
        }).then((res)=>{
            if(res?.data?.code == 200){
                resolve({
                    data:res?.data?.data || res?.data?.obj
                })
            }else reject(res?.data?.message)
        }).catch((e)=>reject(e))
    });
}
export const DeleteAppointment = (id) => {
    return new Promise((resolve,reject)=>{
        axiosCall.post(endpoints.Appointments.delete,{
            id:id
        }).then((res)=>{
            if(res.data.code == 200){
                resolve({
                    status:true,
                    data:res.data.data || res.data.obj
                })
            }else reject(res.data.message)
        }).catch((e)=>{reject(e)})
    });
}


export const AddBulkAppointment = (data) => {
    return new Promise((resolve,reject)=>{
       axiosCall.post(endpoints.Appointments.AddBulk,data)
       .then((res)=>{
        if(res.data.code == 200){
            resolve({
                status:true,
                data:res.data.data || res.data.obj
            })
        }else reject(res.message)
    }).catch((e)=>reject(e));
    })
}
 
  
