import { NavHeader } from "./navheader"

import { Header } from "./header"
import { SideBar } from "./sideBar"
import { ReactNode } from "react"
import { Footer } from "./footer"
import { useLocation } from "react-router"
import { useTranslation } from "react-i18next"


export const Layout = ({ children }: { children?: ReactNode }) => {
    const location = useLocation()
    const { t } = useTranslation()
    
    return <div style={{ position: 'relative' }} id="main-wrapper">
        <NavHeader />
        <Header />
        <SideBar />
        <div className="content-body">
            <div >
                {children}
            </div>
        </div>
        <Footer />

    </div>
}