import { useTranslation } from "react-i18next"
import { changeLang, getLang } from "../../../../helpers/localizationHelper"
import { useLocation, useNavigate } from "react-router"
import { useEffect, useLayoutEffect, useState } from "react"
import { useSelector } from "react-redux"

export const LanguageDropDown = () => {
    const { t } = useTranslation()
    const lang = useSelector((state: any) => state?.language?.getLang)


    return <li className="nav-item dropdown notification_dropdown"> <a className="nav-link"
        role="button" data-bs-toggle="dropdown"> <img
            src={getLang() == "ar-SA" ? "/images/ar-lang.png" : "/images/en-lang.png"} alt="" className="lang-flag" /> </a>
        <div className="dropdown-menu dropdown-menu-end widget-lang">
            <div id="DZ_W_Notification1" className="widget-media dz-scroll p-2 "
                style={{ height: 77 }}>
                <ul className="timeline">
                    {lang && lang?.map((item: any, index: any) => {
                        return <li key={index} onClick={() => {
                            localStorage.setItem("lang", item?.code)

                            window.location.reload()
                        }}>
                            <div className="timeline-panel lang-item">
                                <div className="media-body">
                                    <h6 className="mb-1">{item?.title}</h6>
                                </div>
                                <div className="media me-2"> <img src={item?.code == 'en-US' ? "/images/en-lang.png" : "/images/ar-lang.png"} alt=""
                                    className="lang-flag" /> </div>
                            </div>
                        </li>
                    })}


                </ul>
            </div>
        </div>
    </li>


}