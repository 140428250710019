import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppSwitch } from "../../../components/formsComponents/switch"
import { AppModal } from "../../../components/modal"
import { Loader } from "../../../components/loader"
import { activeWorkingSystem, defaultWorkingSystem, deleteWorkingSystem } from "../../../apis/workingSystem"
import NoDataIcon from "../../../styles/svg/noDataIcon"

export const WorkingSystemDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const [id, setId] = useState(undefined)
    const [isLoading, setLoading] = useState(false)

    const [active, setActive] = useState(false)
    const [defaultC, setDefault] = useState(false)

    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeModal, setActiveModal] = useState(false)
    const [defaultModal, setDefaultModal] = useState(false)

    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {

                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })
        return newObj
    }

    useEffect(() => {
        setTheadValues(thead)
    }, [thead])

    useEffect(() => {
        setTdValues(td?.map((item: any) => transformObj(theadValues, item)))
    }, [td])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {
            if (item?.[0] != 'id') {
                if (index == 0) {
                    return <td key={index}><strong>{item?.[1] == "" ? '#' : item?.[1]}</strong></td>
                }
                if (typeof item?.[1] == 'boolean') {
                    return <td key={index}>{<AppSwitch checked={item?.[1] ?? false}
                        onChange={(event: any) => {
                            if (item?.[0] == 'isActive') {
                                setActiveModal(true)
                                setActive(!item?.[1])
                                setId(itemValue?.["id"])

                            }
                            if (item?.[0] == 'isDefault' && item?.[1] != true) {
                                setDefaultModal(true)
                                setDefault(!item?.[1])
                                setId(itemValue?.["id"])

                            }
                        }} />}
                        </td>
                }
                return <td key={index}>{item?.[1]}</td>
            }
        })
    }
    return <> <div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>
                <tr>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id') {
                            return (
                                <th key={index}><strong>{t(item)}</strong></th>
                            )
                        }
                    })}
                    <th key={20}><strong>{t("actions")}</strong></th>
                </tr>
            </thead>
            <tbody>
                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>{fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a href={`/admin/workingSystems/${tdValue?.id}`} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></a>
                                <a onClick={() => {
                                    setDeleteModal(true)
                                    setId(tdValue?.id)
                                }} className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
        <AppModal
            show={deleteModal}
            onClose={() => {
                setDeleteModal(false)
                setId(undefined)
            }}
            body={<div className="text-center">
                {t('deleteWorkingSystemQuote')}            
                </div>}
            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={t('delete_workingSystem')}
            onSaveClick={async () => {
                setLoading(true)
                const res = await deleteWorkingSystem({ id: id })
                if (res?.status) {
                    setDeleteModal(false)
                    setRequestDone((prev: any) => !prev)
                }
                setLoading(false)
            }} />

        <AppModal
            show={activeModal}
            onClose={() => {
                setActiveModal(false)
                setId(undefined)
                setActive(false)
            }}
            body={<div className="text-center">
                {active ? t('activeWorkingSystemQuote') : t('inactiveWorkingSystemQuote')}            </div>}
            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={active == true ? t('active_workingSystem') : t("inactive_workingSystem")}
            onSaveClick={async () => {
                setLoading(true)
                const res = await activeWorkingSystem({ id: id, flag: active })
                if (res?.status) {
                    setActiveModal(false)
                    setActive(false)
                    setRequestDone((prev: any) => !prev)
                }
                setLoading(false)
            }} />
        <AppModal
            show={defaultModal}
            onClose={() => {
                setDefaultModal(false)
                setId(undefined)
                setDefault(false)

            }}
            body={<div className="text-center">
                {t('defaultWorkingSystemQuote')}            
                </div>}
            closeBtnLabel={t('no')}
            saveBtnLabel={t('yes')}
            title={t('defaultWorkingSystem')}
            onSaveClick={async () => {
                setLoading(true)
                const res = await defaultWorkingSystem({ id: id, flag: defaultC })
                if (res?.status) {
                    setDefaultModal(false)
                    setDefault(false)
                    setRequestDone((prev: any) => !prev)


                }
                setLoading(false)

            }} />



        {isLoading && <Loader />}

    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}