import { useTranslation } from "react-i18next";

export const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-6">
            <div class="error-page">
              <div class="error-inner text-center">
                <div class="dz-error" data-text="404">
                  404
                </div>
                <h4 class="error-head">
                  <i class="fa fa-exclamation-triangle text-warning"></i>{" "}
                  {t("page_not_found")}
                </h4>

                <div>
                  <a href="/admin" class="btn btn-secondary">
                    {t("back_to_dashboard")}{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
