export const AppTextArea = ({ label, placeholder, value, onChange, errorMessage, require}: { errorMessage: string, label?: string, placeholder?: string, value: string, onChange: (val: any) => void,require?:boolean }) => {
    return <div className="col-lg-12 mb-4">

        {label &&
            <h4 className="card-title">
                {label}
            {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </h4>
        }



        <textarea style={{ border: errorMessage ? '1px solid red' : '' }} value={value} onChange={(event) => {
            onChange?.(event.target.value)
        }} className="form-txtarea mt-3 form-control" rows={8} ></textarea>
    </div>
}
