import { ReactNode } from "react"
import { Link, NavLink } from "react-router-dom"


export const SideBarItem = ({ icon, title, route, order }: { icon?: ReactNode, title: string, route: string, order?: number }) => {
    return <li>
        <a key={order ?? title} href={route} >

            {title}
        </a>
    </li>

}