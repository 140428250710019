import ReactPaginate from "react-paginate";
import '../../styles/pagination/pagination.scss';
export const AppPagination = ({
    currentPage = 1,
    pageCount = 7,
    setCurrentPage,

}: { currentPage: number, pageCount: number, setCurrentPage: (val: number) => void, }) => {
    const handlePageClick = ({ selected: selectedPage }: { selected: any }) => {
        setCurrentPage(selectedPage + 1);
    };
    return (
        <div style={{
            maxHeight: 80
        }} className="justify-content-center py-3 d-flex w-100">
            <div style={{ backgroundColor: "white", padding: 8, borderRadius: 10 }}>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    forcePage={currentPage - 1}
                    pageCount={pageCount}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </div>
    );
};

