import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingTenantManagers = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const tenantManagerDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addTenantManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.add, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const restaurantManagerDetails = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.managerRestaurantDetails)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editRestaurantManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.managerRestaurantUpdate, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editTenantManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.edit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const assignTenantManager = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.tenantManagers.assign, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
