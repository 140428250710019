import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { addSubscription, listingRestaurantSubscriptions } from "../../apis/restaurantSupscriptions";
import { resolveRequest, toBase64 } from "../../helpers";
import { GetAllCountries } from "../../apis/lookups";
import { AppButton } from "../../components/button";
import { useTranslation } from "react-i18next";
import { AppModal } from "../../components/modal";
import { AppInput } from "../../components/formsComponents/input";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../helpers/formik";
import { Loader } from "../../components/loader";
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist";
import { AppTextArea } from "../../components/formsComponents/textArea";
import { AppColorPicker } from "../../components/formsComponents/colorPicker";
import { getLang } from "../../helpers/localizationHelper";
import PlusIcon from "../../styles/svg/plusIcon";
import RemoveIcon from "../../styles/svg/removeIcon";
import { editRestaurantManager, restaurantManagerDetails } from "../../apis/tenantManagers";
import { baseURLPhotos } from "../../apis/endpoints";

export const EditRestaurant = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [logoFile, setLogoFile] = useState<any>()
    const [requestDone, setRequestDone] = useState(false)

    const [previewLogo, setPreviewLogo] = useState<any>()
    const [previewBanner, setPreviewBanner] = useState<any>()
    const [bannerFile, setBannerFile] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [allCountries, setAllCountries] = useState()
    const [restaurantDetails, setRestaurantDetails] = useState()
    useEffect(() => {
        resolveRequest(restaurantManagerDetails, setRestaurantDetails)
    }, [getLang(), requestDone])
    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)

    }, [getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await editRestaurantManager({ ...values, bannerImage: bannerFile ?? "", logoImage: logoFile ?? "", isLogoChanged: logoFile || values?.logoImage == null ? true : false, isBannerChanged: bannerFile || values?.bannerImage == null ? true : false })
            if (res?.status) {
                setLogoFile(null)
                setBannerFile(null)
                setPreviewLogo(null)
                setPreviewBanner(null)

                setRequestDone(prev => !prev)

            }

        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)

        }
        setLoading(false)
    };


    const formik = useFormik(
        formikSetup(initialValues, restaurantDetails, validationSchema, 'editRestaurant', submit),
    );

    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <div className="pe-5 ps-5">


        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('edit_restaurant')}
                </p>
                <AppButton title=">" onClick={() => {
                    navigate('/admin/restaurants')
                }} />

            </div>
        </div>

        <div style={{ marginTop: -15 }} className="card p-3 pb-5 pt-2">

            <div className="d-flex flex-wrap  justify-content-between mt-3 mb-4">
                <div className="commonCard">
                    <h6 style={{ textAlign: 'center', marginBottom: 40 }}>{t("logoImage")}</h6>

                    <div style={{ position: 'relative', marginBottom: 20 }}>
                        <input id="file2" accept=".jpg, .png ,.jpeg" onChange={async (event: any) => {
                            setLogoFile(await toBase64(event.target.files?.[0]))
                            const objectUrl = URL.createObjectURL(event.target.files?.[0])
                            setPreviewLogo(objectUrl)
                            setFieldValue('logoImage', null)

                        }} style={{ display: 'none' }} className="form-control mt-3" type="file" />

                        {<img className="commonImage" src={values?.logoImage ? (baseURLPhotos + "/" + values?.logoImage) : previewLogo ? previewLogo : '/noImage.png'} />}
                        <div style={{
                            position: 'absolute', bottom: -10, right: '31%',
                            textAlign: 'center',
                            width: 80,
                            backgroundColor: 'white',
                            borderWidth: .5, borderColor: 'black', borderStyle: 'solid', borderRadius: 20,
                            display: 'flex',
                            justifyContent: 'center'


                        }}>
                            <div style={{ marginInlineEnd: 10 }} onClick={() => {
                                if (logoFile || values?.logoImage) {
                                    setPreviewLogo(null)
                                    setLogoFile(null)
                                    setFieldValue('logoImage', null)
                                    if (document.getElementById("file2")) {
                                        //@ts-ignore
                                        document.getElementById("file2").value = ""
                                    }
                                }


                            }}>
                                <RemoveIcon color={(logoFile || values?.logoImage) ? "url(#wRKXFJsqHCxLE9yyOYHkza)" : "lightGray"} />
                            </div>
                            <label
                                htmlFor="file2"
                                className="plus"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <PlusIcon /></label>

                        </div>
                    </div>


                </div>
                <div className="commonCard">
                    <div style={{ position: 'relative', marginBottom: 20 }}>
                        <h6 style={{ textAlign: 'center', marginBottom: 40 }}>{t("bannerImage")}</h6>
                        {<img id='' className="commonImage" src={values?.bannerImage ? (baseURLPhotos + "/" + values?.bannerImage) : previewBanner ? previewBanner : '/noImage.png'} />}
                        <div style={{
                            position: 'absolute', bottom: -10, right: '31%',
                            textAlign: 'center',
                            width: 80,
                            backgroundColor: 'white',
                            borderWidth: .5, borderColor: 'black', borderStyle: 'solid', borderRadius: 20,
                            display: 'flex',
                            justifyContent: 'center'


                        }}>
                            <input id="file" accept=".jpg, .png ,.jpeg" onChange={async (event: any) => {
                                setFieldValue('bannerImage', null)
                                setBannerFile(await toBase64(event.target.files?.[0]))
                                const objectUrl = URL.createObjectURL(event.target.files?.[0])
                                setPreviewBanner(objectUrl)

                            }} style={{ display: 'none' }} className="form-control mt-3" type="file" />

                            <div style={{ marginInlineEnd: 10 }} onClick={() => {
                                if (bannerFile || values?.bannerImage) {
                                    setPreviewBanner(null)
                                    setBannerFile(null)
                                    setFieldValue('bannerImage', null)
                                    if (document.getElementById("file")) {
                                        //@ts-ignore
                                        document.getElementById("file").value = ""
                                    }
                                }


                            }}>
                                <RemoveIcon color={(bannerFile || values?.bannerImage) ? "url(#wRKXFJsqHCxLE9yyOYHkza)" : "lightGray"} />
                            </div>
                            <label
                                htmlFor="file"
                                className="plus"
                                style={{ padding: 0, margin: 0 }}
                            >
                                <PlusIcon /></label>

                        </div>
                    </div>

                </div>
            </div>
            <div className="row">
                <AppInput placeholder={t('title')} label={t('title')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title ?? ''} />
                <AppDropdownlist errorMessage={isValid('countryId')} require placeholder={t('country')} label={t('country')} onChange={(val) => {
                    setFieldValue('countryId', val?.target?.value)
                }} items={allCountries} value={values?.countryId} />
            </div>
            <div className="row">
                <AppInput placeholder={t('menuUrl')} label={t('menuUrl')} onChange={handleChange('menuUrl')} errorMessage={isValid('menuUrl')} value={values?.menuUrl ?? ''} />
                <AppInput placeholder={t('slug')} label={t('slug')} onChange={handleChange('slug')} errorMessage={isValid('slug')} value={values?.slug} />


            </div>
            <AppTextArea placeholder={t('welcomeMsg')} label={t('welcomeMsg')} onChange={handleChange('welcomeMsg')} errorMessage={isValid('welcomeMsg')} value={values?.welcomeMsg ?? ''} />
            <div className="d-flex justify-content-between">
                <AppColorPicker label={t('addPrimaryColor')} onChange={handleChange('primaryColor')} value={values?.primaryColor ?? ''} />
                <AppColorPicker label={t('addSecondaryColor')} onChange={handleChange('secondaryColor')} value={values?.secondaryColor ?? ''} />
            </div>

            <div className="d-flex justify-content-end mt-5">
                <AppButton title={t("save")} onClick={handleSubmit} />
            </div>
        </div>

        {isLoading && <Loader />}

    </div>
}


