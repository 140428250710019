import { endpoints } from "../endpoints";
import axiosCall from "../axios/axiosConfig";

export const addBranch = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branches.addEdit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const activeBranch = ({ id, flag }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branches.active, { id: id, flag: flag })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteBranch = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branches.delete, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
          });
        } else
          reject({
            status: false,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const listingBranches = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branches.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const BranchDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.branches.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
