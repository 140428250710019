import axiosCall from "../../axios/axiosConfig";
import { endpoints } from "../../endpoints";

export const adminDashboardNumbers = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.adminDashboard.numbers)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const adminDashboardReviewsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.adminDashboard.reviews)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const adminDashboardReservationsChart = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.adminDashboard.reservations)
      .then((res) => {
        if (res?.status == 200) {
          resolve(res?.data);
        } else reject(res?.data?.message ?? "");
      })
      .catch((e) => {
        reject(e);
      });
  });
};
