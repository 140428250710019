// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie'
import { getLang } from '../../helpers/localizationHelper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


export const PieChart = ({ data }: { data: any }) => {
    const [tempData, setTempData] = useState(data)
    const { t } = useTranslation()
    useEffect(() => {
        if (data) {
            let arr: any = []

            data?.map((item: any) => {
                if (item?.id == 1) {
                    arr.push({ ...item, id: t("Terrible") })
                }
                if (item?.id == 2) {
                    arr.push({ ...item, id: t("Poor") })
                }
                if (item?.id == 3) {
                    arr.push({ ...item, id: t("Good") })
                }
                if (item?.id == 4) {
                    arr.push({ ...item, id: t("Great") })
                }
                if (item?.id == 5) {
                    arr.push({ ...item, id: t("Fantastic") })
                }
            })
            setTempData(arr)

        }
    }, [data])
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const colors =
        ["red", "orange", "yellow", "lightgreen", "green"]

    return (
        <div
            className="pie"
            style={{
                height: "500px",
                width: '100%'

            }}
        >
            <ResponsivePie
                colors={colors}
                data={tempData}
                margin={{ top: 40, right: 80, bottom: 150, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            0.2
                        ]
                    ]
                }}

                arcLinkLabelsSkipAngle={20}
                arcLinkLabelsThickness={2}
                arcLinkLabelsTextOffset={getLang() == "ar-SA" ? 30 : 10}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 50,
                        spacing: 10
                    }
                ]}


            />
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', marginTop: -80 }}>
                {tempData?.map((item: any, index: any) => {
                    return <div style={{ display: 'flex', marginInlineEnd: 10, alignItems: 'center' }} key={index}><div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: colors[index] }}></div><span style={{ marginInlineStart: 2 }}>{item?.id}</span></div>
                })}
            </div>
        </div>
    )
}