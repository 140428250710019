import { useTranslation } from "react-i18next"
import { SideBarItem } from "./components/sidebar/sidebarItem"
import { useQuery } from "react-query"
import { UserAllData, UserMenu } from "../../apis/UserProfile"
import { DashboardHeader } from "./dashboardHeader"
export const SideBar = () => {
    const { t } = useTranslation()
    const fetchUserData = async () => {
        /* return await UserMenu() */
        return await UserAllData()
    }
    const { isLoading, isError, error, data, status } = useQuery(['userAllData'], fetchUserData, {
        refetchInterval: 60000,
        select: (data) => data.data
    })

    function compareNumbers(a: any, b: any) {
        return a?.props?.order - b?.props?.order;
    }

    return <div className="deznav">
        {/* { status == 'success' && */}
            <div className="deznav-scroll">
                <ul className="metismenu" id="menu">
                    <li className="menu-title">{t("adminPanel")}</li>
                    {
                        // isLoading === false ?
                        DashboardHeader.map((item: any, index) => {
                            if (data?.menuItems?.findIndex((el: any) => el.groupId == item.groupId) != -1)
                                return (
                                    <li key={index}>
                                        <a className="has-arrow"
                                            aria-expanded="false">
                                            <div className="menu-icon">
                                                {item.icon}
                                            </div>
                                            <span className="nav-text">
                                                {t(item?.name)}
                                            </span>
                                        </a>
                                        <ul aria-expanded="false">
                                            {
                                                data?.menuItems.map((dashboardItem: any) => {
                                                    if (dashboardItem.groupId == item.groupId) {
                                                        return <SideBarItem
                                                            order={dashboardItem.order}
                                                            key={dashboardItem.title}
                                                            title={dashboardItem.title}
                                                            route={dashboardItem.link} />
                                                    }
                                                }).sort(compareNumbers)
                                            }
                                        </ul>
                                    </li>
                                )
                        })

                        //  :<li className="text-white">loading ...</li>
                    }
                </ul>
            </div>
        {/* } */}
    </div>
}