import { endpoints } from "../endpoints";
import axiosCall from "../axios/axiosConfig";

export const addRestaurant = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.addRestaurant, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editRestaurant = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.edit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const activeRestaurant = ({ id, flag }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.active, { id: id, flag: flag })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const publishRestaurant = ({ id, flag }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.publish, { id: id, flag: flag })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteRestaurant = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.delete, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
          });
        } else
          reject({
            status: false,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const listingRestaurant = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const listingRestaurantsWithoutPagination = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.lookup)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const RestaurantDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
