import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { getLang } from "../../helpers/localizationHelper"
import { resolveRequest } from "../../helpers"
import { GetAllWorkingDays, GetAllWorkingDaysAdd, GetAllWorkingSystemsTypes } from "../../apis/lookups"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../helpers/formik"
import { useFormik } from "formik"
import { AppButton } from "../../components/button"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { Loader } from "../../components/loader"
import { WorkingSystemDetails, editWorkingSystem } from "../../apis/workingSystem"
import { AppDateTimepicker } from "../../components/formsComponents/datePicker"
import { addWorkingDays, listingWorkingDays } from "../../apis/workingSystemDay"
import { DaysDataTable } from "./components/daysDataTable"
import { AppModal } from "../../components/modal"
import { AppSwitch } from "../../components/formsComponents/switch"
import moment from "moment"
import { AppTimepicker } from "../../components/formsComponents/timePicker"

export const EditWorkingSystemScreen = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [requestDone, setRequestDone] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [allWorkingSystemTypes, setAllWorkingSystemTypes] = useState()
    const [allWorkingSystemWeekAdd, setAllWorkingWeekAdd] = useState()
    const tabs = [
        { id: 1, name: t('default') },
        { id: 2, name: t('waiting') },

    ]
    const [currentTab, setCurrentTab] = useState(1)
    const [allWorkingDays, setAllWorkingDays] = useState<any>()
    const [addModal, setAddModal] = useState(false)
    const [workingSystemDetails, setWorkingSystemDetails] = useState()
    
    useEffect(() => {
        resolveRequest(WorkingSystemDetails, setWorkingSystemDetails, { id: id })
        resolveRequest(listingWorkingDays, setAllWorkingDays, { id: id })
        resolveRequest(GetAllWorkingDays, setAllWorkingWeekAdd, { id: id })
    }, [id, getLang(), requestDone])
    useEffect(() => {
        resolveRequest(GetAllWorkingSystemsTypes, setAllWorkingSystemTypes)
    }, [getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await editWorkingSystem({ ...values, id: id, })


        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, workingSystemDetails, validationSchema, 'editWorkingSystem', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;

    const isValid = formikFieldValidation(touched, errors);
    const submit2 = async (values: any,) => {
    
        setLoading(true)
        try {
            const res = await addWorkingDays({
                ...values, id: null, workingSystemId: id, startTime: moment(values?.startTime).format("YYYY-MM-DDTHH:mm:ss"), endTime: moment(values?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
            })
            if (res?.status) {
                resetForm({ values: {} })
                setAddModal(false)
                setRequestDone((prev: any) => !prev)
                setCurrentTab(1)
            }
        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };


    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addWorkingSystemDay', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, setFieldValue: setFieldValue2, setFieldError: setFieldError2, resetForm } = formik2;

    const isValid2 = formikFieldValidation(touched2, errors2);
    //     function onchange(args: ChangedEventArgs | any) {
    //         (document.getElementById('date_label') as HTMLElement).textContent = 'Selected Value: ' + args.value.toLocaleDateString();
    // }
    return <div className="pe-5 ps-5">
        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('edit_workingSystem')}
                </p>
                <AppButton title=">" onClick={() => {
                    navigate('/admin/workingSystems')
                }} />

            </div>
        </div>

        <div style={{ marginTop: -15 }} className="card p-3 pb-5 pt-2">
            <div className="row">
                <AppInput label={t('title')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                <AppDropdownlist errorMessage={isValid('workingSystemTypeId')} require
                    label={t('workingSystemType')} onChange={(val) => {
                        setFieldValue('workingSystemTypeId', val?.target?.value)
                    }} items={allWorkingSystemTypes} value={values?.workingSystemTypeId} />
            </div>
            <div className="d-flex justify-content-end mt-5">
                <AppButton title={t("save")} onClick={handleSubmit} />
            </div>
        </div>
        <div className="card p-3 mt-5 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('workingSystemDays')}
                </p>
                {/* {allWorkingDays?.length < 7 &&  */}
                <AppButton title={t("add_workingSystemDay")} onClick={() => {
                    setAddModal(true)
                }} />
                {/* } */}
            </div>
        </div>
        <div style={{ marginTop: -15 }} className="card p-3  pb-1 pt-1">

            <DaysDataTable setRequestDone={setRequestDone} thead={["weekDay", "startTime", "endTime", "id", "timeIncrement", "allowWaitingList","guestsNumberPerReservation"]} td={allWorkingDays} />
        </div>
        <AppModal
            className="modal2"
            show={addModal}
            onClose={() => {
                resetForm({ values: {} })
                setAddModal(false)
                setCurrentTab(1)
            }}
            body={
                <>
                    <div className="row">
                        <AppDropdownlist errorMessage={isValid2('weekDayId')} require
                            label={t('weekDay')} onChange={(val) => {
                                setFieldValue2('weekDayId', val?.target?.value)
                            }} items={allWorkingSystemWeekAdd}
                            value={values2?.weekDayId} />
                        <AppInput type="number" label={t('timeIncrement')} onChange={handleChange2('timeIncrement')} errorMessage={isValid2('timeIncrement')} value={values2?.timeIncrement} />
                    </div>
                    <div className="row">
                        <AppTimepicker require label={t('startTime')} onChange={(e) => {
                            setFieldValue2('startTime', e)
                        }} errorMessage={isValid2('startTime')} value={values2?.startTime} />
                        <AppTimepicker require label={t('endTime')} onChange={(e) => {
                            setFieldValue2('endTime', e)
                        }} errorMessage={isValid2('endTime')} value={values2?.endTime} />

                    </div>
                    <div className="row">
                        <AppInput type="number" label={t('guestsNumberPerReservation')} onChange={handleChange2('GuestsNumberPerReservation')} errorMessage={isValid2('GuestsNumberPerReservation')} value={values2?.GuestsNumberPerReservation} />
                    </div>
                    <div style={{ borderWidth: 1, borderStyle: 'dashed', padding: 20, borderRadius: 10 }}>
                        <div className="d-flex justify-content-center flex-wrap" style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', padding: 0, marginBottom: 20 }}>
                            {tabs?.map((item => {
                                return <p style={{
                                    marginBlock: 0,

                                    color: item?.id == currentTab ? "#886bff" : "black", borderBottomColor: item?.id == currentTab ? "#886bff" : "transparent", borderBottomWidth: 1, borderBottomStyle: 'solid', cursor: 'pointer', paddingInline: 20
                                }} onClick={() => {
                                    setCurrentTab(item?.id)
                                }}>{item?.name}</p>
                            }))}
                        </div>
                        {currentTab == 1 && <>

                            <div className="row">
                                <AppInput type="number" label={t('maxReservationsNumber')} onChange={handleChange2('maxReservationsNumber')} errorMessage={isValid2('maxReservationsNumber')} value={values2?.maxReservationsNumber} />
                                <AppInput type="number" label={t('maxGuestsNumber')} onChange={handleChange2('maxGuestsNumber')} errorMessage={isValid2('maxGuestsNumber')} value={values2?.maxGuestsNumber} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('downPaymentPerReservation')} onChange={handleChange2('downPaymentPerReservation')} errorMessage={isValid2('downPaymentPerReservation')} value={values2?.downPaymentPerReservation} />
                                <AppInput type="number" label={t('downPaymentPerGuest')} onChange={handleChange2('downPaymentPerGuest')} errorMessage={isValid2('downPaymentPerGuest')} value={values2?.downPaymentPerGuest} />
                            </div>
                        </>}

                        {currentTab == 2 && <>
                            <div className="row">
                                <AppSwitch className="col-xl-6 col-lg-6 mb-4 " label={t("allowWaitingList")} onChange={(e: any) => {
                                    setFieldValue2('allowWaitingList', e)
                                }} checked={values2?.allowWaitingList} />
                            </div>
                            <div className="row">
                                <AppInput type="number" label={t('maxWaitingReservationsNumber')} onChange={handleChange2('maxWaitingReservationsNumber')} errorMessage={isValid2('maxWaitingReservationsNumber')} value={values2?.maxWaitingReservationsNumber} />
                                <AppInput type="number" label={t('maxWaitingGuestsNumber')} onChange={handleChange2('maxWaitingGuestsNumber')} errorMessage={isValid2('maxWaitingGuestsNumber')} value={values2?.maxWaitingGuestsNumber} />
                            </div>
                        </>}
                    </div>
                </>
            } title={t('add_workingSystemDay')} onSaveClick={handleSubmit2} />

        {isLoading && <Loader />}

    </div>
}