import { BranchManagersScreen } from "../screens/branchManagers";
import { BranchesScreen } from "../screens/branches";
import { EditBranchScreen } from "../screens/branches/editBranch";
import { AdminDashboardScreen } from "../screens/adminDashboard";
import { ReservationsScreen } from "../screens/reservations";
import { ReservationDetailsScreen } from "../screens/reservations/reservationDetails";
import { SurveyScreen } from "../screens/surveys";
import { EditSurveyScreen } from "../screens/surveys/editSurvey";
import { TenantListingScreen } from "../screens/tenant";
import { EditTenant } from "../screens/tenant/editTenant";
import { TenantManagersScreen } from "../screens/tenantManagers";
import { WorkingSystemScreen } from "../screens/workingSystem";
import { EditWorkingSystemScreen } from "../screens/workingSystem/editWorkingSystem";
import { DashboardIcon } from "../styles/svg/logos/sidebar/dashboardIcon";
import { ChangePasswordScreen } from "../screens/auth/changePassword";
import { ProfileScreen } from "../screens/auth/profile";
import { EditRestaurant } from "../screens/tenantManagers/editRestaurant";
import ContactRequestsScreen from "../screens/contactRequests";
import NewsLettersScreen from "../screens/NewsLetters";
import EditContactRequestsScreen from "../screens/contactRequests/EditContactRequests";
import AppointmentsScreen from "../screens/Appointments";
export const appRoutes = [
  {
    id: "dashboard",
    name: "dashboard",
    icon: <DashboardIcon />,
    component: <AdminDashboardScreen />,
    link: "/admin",
    permission: undefined,
    side: true,
  },
  {
    id: "restaurants",
    name: "restaurants",
    icon: <DashboardIcon />,
    component: <TenantListingScreen />,
    link: "/admin/restaurants",
    permission: undefined,
    side: true,
  },
  {
    id: "restaurantsEdit",
    name: "restaurantsEdit",
    icon: <DashboardIcon />,
    component: <EditTenant />,
    link: "/admin/restaurants/:id",
    permission: undefined,
    side: false,
  },
  {
    id: "surveys",
    name: "surveys",
    icon: <DashboardIcon />,
    component: <SurveyScreen />,
    link: "/admin/surveys",
    permission: undefined,
    side: true,
  },
  {
    id: "surveysEdit",
    name: "surveysEdit",
    icon: <DashboardIcon />,
    component: <EditSurveyScreen />,
    link: "/admin/surveys/:id",
    permission: undefined,
    side: false,
  },
  {
    id: "branches",
    name: "branches",
    icon: <DashboardIcon />,
    component: <BranchesScreen />,
    link: "/admin/branches",
    permission: undefined,
    side: true,
  },
  {
    id: "branchesEdit",
    name: "branchesEdit",
    icon: <DashboardIcon />,
    component: <EditBranchScreen />,
    link: "/admin/branches/:id",
    permission: undefined,
    side: false,
  },
  {
    id: "workingSystems",
    name: "workingSystems",
    icon: <DashboardIcon />,
    component: <WorkingSystemScreen />,
    link: "/admin/workingSystems",
    permission: undefined,
    side: true,
  },
  {
    id: "editWorkingSystem",
    name: "editWorkingSystem",
    icon: <DashboardIcon />,
    component: <EditWorkingSystemScreen />,
    link: "/admin/workingSystems/:id",
    permission: undefined,
    side: false,
  },
  {
    id: "reservations",
    name: "reservations",
    icon: <DashboardIcon />,
    component: <ReservationsScreen />,
    link: "/admin/reservations",
    permission: undefined,
    side: true,
  },
  {
    id: "editWorkingSystem",
    name: "editWorkingSystem",
    icon: <DashboardIcon />,
    component: <ReservationDetailsScreen />,
    link: "/admin/reservations/:id",
    permission: undefined,
    side: false,
  },

  {
    id: "branchManagers",
    name: "branchManagers",
    icon: <DashboardIcon />,
    component: <BranchManagersScreen />,
    link: "/admin/branchManagers",
    permission: undefined,
    side: true,
  },
  {
    id: "tenantManagers",
    name: "tenantManagers",
    icon: <DashboardIcon />,
    component: <TenantManagersScreen />,
    link: "/admin/tenantManagers",
    permission: undefined,
    side: true,
  },
  /*   {
    id: "changePassword",
    name: "changePassword",
    icon: <DashboardIcon />,
    component: <ChangePasswordScreen />,
    link: "/admin/changePassword",
    permission: undefined,
    side: false,
  }, */
  {
    id: "updateProfile",
    name: "updateProfile",
    icon: <DashboardIcon />,
    component: <ProfileScreen />,
    link: "/admin/profile",
    permission: undefined,
    side: false,
  },
  {
    id: "editRestaurant",
    name: "editRestaurant",
    icon: <DashboardIcon />,
    component: <EditRestaurant />,
    link: "/admin/editRestaurant",
    permission: undefined,
    side: true,
  },
  {
    id: "contactRequests",
    name: "contactRequests",
    icon: <DashboardIcon />,
    component: <ContactRequestsScreen />,
    link: "/admin/contactRequests",
    permission: undefined,
    side: true,
  },
  {
    id: "EditContactRequests",
    name: "EditContactRequests",
    icon: <DashboardIcon />,
    component: <EditContactRequestsScreen />,
    link: "/admin/contactRequests/:id",
    permission: undefined,
    side: false,
  },
  {
    id: "Newsletters",
    name: "Newsletters",
    icon: <DashboardIcon />,
    component: <NewsLettersScreen />,
    link: "/admin/Newsletters",
    permission: undefined,
    side: true,
  },
  {
    id: "appointments",
    name: "appointments",
    icon: <DashboardIcon />,
    component: <AppointmentsScreen/>,
    link: "/admin/appointments",
    permission: undefined,
    side: true,
  }
];
