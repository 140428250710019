import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { LoginScreen } from "../screens/login";
import { Layout } from "../components/layout";
import Cookies from "universal-cookie";

const PublicRoute = ({ children }) => {
  let isAuth = "";
  const cookies = new Cookies();

  isAuth = cookies.get("token");

  if (isAuth == undefined) {
    return <LoginScreen />;
  }
  return <Navigate to={"/admin"} />;
};

export default PublicRoute;
